import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React from "react";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";

export default function CSVFormat({ className = "" }) {
  const downloadCSVFormatData = async () => {
    try {
      const res = await fetchData("GET", `${apiUrl}/users/csv-format-download`);
      if (res.status === 200) {
        const blob = await res.blob();
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "upload_users_format.csv"; 
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Server responded with an error:", res.statusText);
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };
  return (
    <div className={classNames("", className)}>
      <button
        onClick={downloadCSVFormatData}
        className="min-w-[120px] flex font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer">
        <div className="group relative hover:bg-gray-200/20 py-1 px-2 flex rounded items-center gap-x-2">
          <ArrowDownTrayIcon className="relative group-hover:top-[0px] top-[-2px] transition-all duration-100 w-6 h-6" /> CSV Format
        </div>
      </button>
    </div>
  );
}
