import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { getAuthorizedUser, logout } from "src/actions/auth";

const ProtectedRoute = ({ roles = ["super", "admin"], user, auth, children, getAuthorizedUser, site, logout }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const ac = new AbortController();

    const loadUser = async () => {
      if (!auth.status && localStorage.getItem("access_token")) {
        try {
          await getAuthorizedUser(ac.signal);
        } catch (error) {
          logout();
        }
      } else if (localStorage.getItem("userId") && !localStorage.getItem("access_token")) {
        logout();
      } else if (!localStorage.getItem("access_token")) {
        logout();
      }
    };

    loadUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.status, getAuthorizedUser, logout, window.location.pathname]);

  useEffect(() => {
    if (roles && user) {
      if (!roles.includes(user.type)) {
        if (user?.type === "admin") {
          // navigate("/pages");
        }
        if (user?.type === "editor" && user?.workspaces?.length > 1) {
          navigate("/workspaces");
        } else if(user?.type === "editor" && user?.workspaces?.length === 1) {
          navigate(`/workspaces/${user?.workspaces[0]?._id}`);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, user, window.location.pathname]);

  useEffect(() => {
    if (site && site.api_state === "DOMO_DATASET" && window.location.pathname === "/users/bulk-upload") {
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site.api_state, window.location.pathname]);

  if (!localStorage.getItem("access_token")) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser, logout })(ProtectedRoute);
