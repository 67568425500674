import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { manageWorkspaceSettings } from "src/actions/workspace";
import LeftNavigation from "src/assets/svgs/left_navigation.svg";
import TopNavigation from "src/assets/svgs/top_navigation.svg";
import Section from "src/components/Shared/Containers/Section";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { H3 } from "src/components/Shared/Text/Headers";
import { noFavicon } from "src/config/host";
import { urlBuilder } from "src/helpers/urlBuilder";
import useFetch from "src/hooks/useFetch";

const WorkspaceLayoutStyles = ({ setWorkspace = () => {}, ...props }) => {
  const { id } = useParams();
  const [layoutType, setLayoutType] = useState("");
  const [layoutLandingPageId, setLayoutLandingPageId] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [squareLogoButtonLoader, setSquareLogoButtonLoader] = useState(false);
  const [squareLogoButtonIsDisabled, setSquareLogoButtonIsDisabled] = useState(true);
  const [useGlobalSettings, setUseGlobalSettings] = useState(false);
  const [updatedSite, setUpdatedSite] = useState({});

  const options = [
    {
      key: (
        <div className="h-7 flex items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor"
            src={LeftNavigation}
            alt="left navigation"
          />
          <p className="text-xl">Vertical</p>
        </div>
      ),
      value: "LEFT_NAVIGATION",
    },
    {
      key: (
        <div className="h-7 flex items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor hover:text-highlightColor"
            src={TopNavigation}
            alt="top navigation"
          />
          <p className="text-xl">Horizontal</p>
        </div>
      ),
      value: "TOP_NAVIGATION",
    },
  ];

  const {
    response: { data: workspace },
    refreshData: refreshWorkspace,
  } = useFetch(urlBuilder(`/workspaces/:workspace_id`, { workspace_id: id }));

  useEffect(() => {
    const setUser = async () => {
      await props.getAuthorizedUser();
    };
    setUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspace?.layout_type) setLayoutType(workspace?.layout_type);
  }, [workspace?.layout_type]);

  useEffect(() => {
    setLayoutLandingPageId(workspace.layout_landing_page_id);
  }, [workspace.layout_landing_page_id]);

  useEffect(() => {
    setUseGlobalSettings(workspace.use_global_settings);
  }, [workspace.use_global_settings]);

  useEffect(() => {
    if (workspace?._id) {
      setUpdatedSite(getSiteData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace._id]);

  const getSiteData = (type = "all") => {
    if (type === "all") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        name: workspace.name,
        image_logo: workspace.image_logo || "",
        square_logo: workspace.square_logo || "",
        image_favicon: workspace.image_favicon || noFavicon,
        top_bar_color: workspace?.top_bar_color || "#FFFFFF",
        top_bar_text_color: workspace?.top_bar_text_color || "#FFFFFF",
        side_bar_color: workspace?.side_bar_color || "#FFFFFF",
        side_bar_text_color: workspace?.side_bar_text_color || "#FFFFFF",
        side_bar_shade_color: workspace?.side_bar_shade_color || "#FFFFFF",
        left_nav_active_text_color: workspace?.left_nav_active_text_color || "#FFFFFF",
        highlight_color: workspace?.highlight_color || "#FFFFFF",
        background_color: workspace?.background_color || "#FFFFFF",
        sso_button_color: workspace.sso_button_color,
        sso_button_text_color: workspace.sso_button_text_color,
        use_global_settings: workspace.use_global_settings || false,
        max_width: workspace.max_width
      };
    } else if (type === "layout") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        name: workspace.name,
        image_logo: workspace.image_logo || "",
        image_favicon: workspace.image_favicon || noFavicon,
        top_bar_color: workspace?.top_bar_color || "#FFFFFF",
        top_bar_text_color: workspace?.top_bar_text_color || "#FFFFFF",
        side_bar_color: workspace?.side_bar_color || "#FFFFFF",
        side_bar_text_color: workspace?.side_bar_text_color || "#FFFFFF",
        side_bar_shade_color: workspace?.side_bar_shade_color || "#FFFFFF",
        left_nav_active_text_color: workspace?.left_nav_active_text_color || "#FFFFFF",
        highlight_color: workspace?.highlight_color || "#FFFFFF",
        background_color: workspace?.background_color || "#FFFFFF",
        sso_button_color: workspace.sso_button_color,
        sso_button_text_color: workspace.sso_button_text_color,
        use_global_settings: workspace.use_global_settings || false,
        max_width: workspace.max_width
      };
    } else if (type === "square_logo") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        square_logo: workspace.square_logo || "",
      };
    }
  };

  const handleStylesDataChange = (keyValue = {}) => {
    setButtonIsDisabled(false);
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleSquareLogoStylesDataChange = (keyValue = {}) => {
    setSquareLogoButtonIsDisabled(false);
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleStylesUpdate = async (body) => {
    try {
      setButtonLoader(true);

      if (body.max_width && !/^(\d{1,2}(\.\d+)?%|100%|\d+(?:\.\d+)?px)$/.test(body.max_width)) {
        toast.error(
          "Please enter a valid max width. For example, 100% or 100px."
        );
        setButtonLoader(false);
        return
      }

      if (body.image_favicon !== workspace.image_favicon) {
        // await apiRequest("DELETE", `/workspaces/${body._id}/settings/images/image_favicon`);
      }
      if (body.image_logo !== workspace.image_logo) {
        // await apiRequest("DELETE", `/workspaces/${body._id}/settings/images/image_logo`);
      }
      let payload = {
        ...body,
        workspace_id: body._id,
        layout_type: layoutType,
      };

      if (layoutType === "CUSTOM_NAVIGATION" && workspace.workspace_type === "IFRAME_EMBED") {
        if (!layoutLandingPageId) {
          toast.error('You must select a landing page when "No Navigation" is selected');
          return;
        }
        payload.layout_landing_page_id = layoutLandingPageId;
      }

      const message = await props.manageWorkspaceSettings({ workspace_id: body._id, ...body, layout_type: layoutType, layout_landing_page_id: layoutLandingPageId });
      refreshWorkspace();
      setButtonLoader(false);
      toast.success(message);
    } catch (error) {
      setButtonLoader(false);
      toast.error(error.message);
    } finally {
      setButtonIsDisabled(true);
    }
  };

  const handleStylesUpdateSquareLogo = async (body) => {
    try {
      setSquareLogoButtonLoader(true);
      const message = await props.manageWorkspaceSettings({ workspace_id: body._id, ...body });
      refreshWorkspace();
      setSquareLogoButtonLoader(false);
      toast.success(message);
    } catch (error) {
      setSquareLogoButtonLoader(false);
      toast.error(error.message);
    } finally {
      setSquareLogoButtonIsDisabled(true);
    }
  };

  return (
    <Section>
      <div className="grid gap-y-4">
        {workspace.workspace_type === "IFRAME_EMBED" && (
          <div>
            <div className="flex justify-between items-center">
              <H3 caption="View multiple options for this workspace's end user.">Select layout orientation</H3>
            </div>
            <div className="w-56">
              <SelectMenu
                size="md"
                options={options}
                startIndex={options.findIndex((option) => option.value === layoutType)}
                setOption={(option) => {
                  setLayoutType(option.value);
                  handleStylesDataChange({ layout_type: option.value });
                }}
              />
            </div>
          </div>
        )}
        <div>
          <div className="flex justify-between items-center">
            <H3 caption="White-label the customer experience with customize colors and logos.">Choose your colors</H3>
            <div className="min-w-[260px]">
              <ToggleHeader
                title="Use default styles"
                subtitle="Override any workspace styles."
                position="left">
                <Toggle
                  checked={useGlobalSettings}
                  onChange={() => {
                    setUseGlobalSettings(!useGlobalSettings);
                    handleStylesDataChange({ use_global_settings: !useGlobalSettings });
                  }}
                />
              </ToggleHeader>
            </div>
          </div>
          <StylesBuilder
            data={workspace}
            propsButtonOption={{
              buttonLoader: buttonLoader,
              buttonIsDisabled: buttonIsDisabled,
              squareLogoButtonLoader: squareLogoButtonLoader,
              squareLogoButtonIsDisabled: squareLogoButtonIsDisabled,
            }}
            setButtonIsDisabled={setButtonIsDisabled}
            setSquareLogoButtonIsDisabled={setSquareLogoButtonIsDisabled}
            onSubmit={handleStylesUpdate}
            onSquareLogoSubmit={handleStylesUpdateSquareLogo}
            updatedSite={updatedSite}
            setUpdatedSite={setUpdatedSite}
            getSiteData={getSiteData}
            handleStylesDataChange={handleStylesDataChange}
            handleSquareLogoStylesDataChange={handleSquareLogoStylesDataChange}
            title="Layout & Styles"
            secondaryTitle="(Colors, logo, favicon, etc.)"
            layoutType={layoutType}
            setWorkspace={setWorkspace}
          />
        </div>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return { site: state.site };
};

export default connect(mapStateToProps, { getAuthorizedUser, manageWorkspaceSettings })(WorkspaceLayoutStyles);
