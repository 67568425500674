import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { userPermissionCheckboxManage, userPermissionList, usersPermissionManage } from "src/actions/workspace";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { classNames } from "src/helpers/classNames";
import WorkspacePermissionTabData from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissionTabData";
import useFetch from "src/hooks/useFetch";
import { urlBuilder } from "src/helpers/urlBuilder";
import { apiRequest } from "src/async/apiUtils";
import Section from "src/components/Shared/Containers/Section";
import { H3 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";

const WorkspacePermissions = ({ workspaceId, workspaceDetails = {}, ...props }) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedTab, setSelectedTab] = useState("user");
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [disableStatus, setDisableStatus] = useState(false);
  const [permissionChanges, setPermissionChanges] = useState(0);
  const tabs = [
    { name: "Pages", code: "page" },
    { name: "Users", code: "user" },
    { name: "Groups", code: "group" },
    { name: "Permissions", code: "permission" },
    { name: "Layout & Styles", code: "layout" },
    { name: "Integrations", code: "integration" },
  ];

  const handleAddUserModal = (value) => {
    setIsAddUserModalOpen(value);
    resetUserIds();
  };

  const resetUserIds = () => {
    let userIds = editorUsers.map((user) => user?.user_id?._id);
    setSelectedUser(userIds);
    setUserList(editorUsers);
  };

  const {
    response: { data: editorUsers },
    refreshData: refreshEditorUsers,
  } = useFetch(urlBuilder(`/workspaces/:workspace_id/users/permissions/list`, { workspace_id: workspaceId }));

  const getUsers = async () => {
    refreshEditorUsers();
  };

  useEffect(() => {
    resetUserIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorUsers]);

  useEffect(() => {
    loadWorkspaceUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  const loadWorkspaceUserData = async () => {
    try {
      const res = await apiRequest("get", `/workspaces/${workspaceId}/users`, {});

      if (res.data) {
        setWorkspaceUsers(res.data.data);
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const userAdd = async (selectedUser) => {
    try {
      setPermissionChanges(0);
      setDisableStatus(true);
      const data = await props.usersPermissionManage({ user_id: selectedUser, workspace_id: workspaceId });
      getUsers();
      setIsAddUserModalOpen(false);
      setSelectedUser([]);
      toast.success(data.message);
      setDisableStatus(false);
    } catch (error) {
      setDisableStatus(false);
      setIsAddUserModalOpen(false);
      setSelectedUser([]);
      toast.success(error.message);
    }
  };

  const handleSinglePermissionChange = async (e, currentUser) => {
    try {
      let permissions = [];
      let resetUserList = [];
      for (let i = 0; i < userList?.length; i++) {
        const user = userList[i];
        if (currentUser._id === user._id) {
          permissions.push({ _id: user._id, [e.target.name]: e.target.checked });
          resetUserList.push({ ...user, [e.target.name]: e.target.checked });
        } else {
          resetUserList.push(user);
        }
      }
      setPermissionChanges((prevData) => (prevData + 1))
      setUserList(resetUserList);
    } catch (error) { }
  };

  const handleMultiplePermissionChanges = async (e) => {
    try {
      let permissions = [];
      let resetUserList = [];
      for (let i = 0; i < userList?.length; i++) {
        const user = userList[i];
        permissions.push({ _id: user._id, [e.target.name]: e.target.checked });
        resetUserList.push({ ...user, [e.target.name]: e.target.checked });
      }
      setPermissionChanges((prevData) => (prevData + 1))
      setUserList(resetUserList);
    } catch (error) { }
  };

  const onClear = () => {
    setPermissionChanges(0);
    setUserList(editorUsers);
  }

  const savePermissionChanges = async () => {
    try {
      setPermissionChanges(0);
      const resp = await props.userPermissionCheckboxManage({ permissions: userList, workspace_id: workspaceId });
      toast.success(resp);
      refreshEditorUsers();
    } catch (error) {
      toast.error("Failed to update.");
    }
  }

  return (
    <>
      <Section>
        <H3 caption="Workspace editors have access to only the channels that you specify.">Manage editor permissions</H3>
        <div className="mt-8 relative w-full rounded-md space-y-4 h-auto">
          <>
            <div className="relative w-full max-w-9xl rounded-md bg-white">
              <div className="relative bg-white">
                <nav
                  className="flex"
                  aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.code}
                      onClick={() => setSelectedTab(tab.code)}
                      className={classNames(
                        tab.code === selectedTab ? "bg-leftNavActiveColor text-leftNavActiveTextColor border-leftNavActiveTextColor" : "border-gray-100 bg-leftNavColor text-leftNavTextColor hover:text-leftNavActiveTextColor",
                        "border-x-2 border-t-2 max-w-[200px] py-4 px-6 hover:bg-leftNavActiveColor w-full flex justify-center items-center rounded-t-md cursor-pointer"
                      )}
                      aria-current={tab.code === selectedTab ? "page" : undefined}>
                      <span className="font-semibold">{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
              <div className="border-2 border-gray-200 rounded-md rounded-tl-none">
                <div className="mt-4">
                  <WorkspacePermissionTabData
                    workspaceId={workspaceId}
                    isAddUserModalOpen={isAddUserModalOpen}
                    setIsAddUserModalOpen={setIsAddUserModalOpen}
                    userList={userList}
                    disabledColumns={tabs.filter((tab) => tab.code === selectedTab)[0].disabledSections}
                    selectedTab={selectedTab}
                    handleSinglePermissionChange={handleSinglePermissionChange}
                    handleMultiplePermissionChanges={handleMultiplePermissionChanges}
                  />
                </div>
              </div>
              {userList.length > 0 && (
                <div className={classNames("p-4 border-t-[1px] border-gray-200 w-full flex justify-end gap-x-4 mt-20 sm:mt-4 transition-all duration-150 pt-4", permissionChanges > 0 ? "bg-gray-50" : "")}>
                  <Button
                    version="gray"
                    onClick={onClear}
                    disabled={permissionChanges < 1}
                  >
                    Undo changes
                  </Button>
                  <Button
                    onClick={savePermissionChanges}
                    disabled={permissionChanges < 1}
                  >
                    Update
                  </Button>
                </div>
              )}
            </div>
          </>
        </div>
      </Section>
      <WorkspaceManageUsersModal
        title="Workspace Editors"
        secondaryTitle="Manage"
        isPermissionPage={true}
        isOpen={isAddUserModalOpen}
        setIsOpen={handleAddUserModal}
        pSelectedUser={selectedUser}
        defaultSelectedUsers={selectedUser}
        setSelectedUser={setSelectedUser}
        onSuccess={userAdd}
        onCancel={() => setIsAddUserModalOpen(false)}
        users={editorUsers}
        workspaceUsers={workspaceUsers}
        disableStatus={disableStatus}
        workspaceDetailsData={workspaceDetails}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userPermissionList, usersPermissionManage, userPermissionCheckboxManage })(WorkspacePermissions);
