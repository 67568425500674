import Modal from "src/components/Shared/Modal";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import Input from "src/components/Shared/Forms/Inputs/Input";

const WorkspaceImportUserModal = ({
  isImportModalOpen = false,
  setIsImportModalOpen = () => {},
  selectedUsers = [],
  workspaceUsers = [],
  sendWelcomeEmail = false,
  setSendWelcomeEmail = () => {},
  password = "",
  setPassword = () => {},
  confirmPassword = "",
  setConfirmPassword = () => {},
  onSuccess = () => {},
  hideCrossIcon = true,
  disableStatus = false,
  ...props
}) => {
  return (
    <Modal
      title={props.workspaceDetailsData?.name}
      secondaryTitle={`Import User(s)`}
      isOpen={isImportModalOpen}
      onSuccess={() => onSuccess(selectedUsers, true, true)}
      onCancel={() => onSuccess(selectedUsers, false, true)}
      hideCrossIcon={hideCrossIcon}
      onClose={() => setIsImportModalOpen(false)}
      defaultOptions={{
        onSuccessButtonText: "Yes",
        onCancelButtonText: "No",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disableStatus,
        onCancelLoaderVisible: true,
        onCancelLoaderStart: disableStatus,
      }}
      defaultStyles={{
        overFlowYVisible: true,
      }}>
      <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">The following people are not currently users in the portal. Would you like to add them?</div>

      <div className="flex flex-col mt-5 pl-5 space-y-3">
        {workspaceUsers.filter((user) => selectedUsers.includes(user.id || user._id) && !user.portalUser).length
          ? workspaceUsers
              .filter((user) => selectedUsers.includes(user.id || user._id) && !user.portalUser)
              .map((user) => (
                <div
                  key={user.id || user._id}
                  className="text-sm text-black font-semibold">
                  {user.name} (not a portal user)
                </div>
              ))
          : "No users available to import"}
      </div>

      <div className="w-full px-2 flex my-5">
        <div className="ml-3 -mt-[2px] text-sm text-gray-600 w-full">
          <p className="p-0">Send welcome email on setup</p>
          <p className="p-0 text-gray-400/80">When disabled, users will need to follow the "reset password process" before logging in.</p>
        </div>

        <div className="flex items-center justify-end min-w-[120px]">
          <Toggle
            checked={sendWelcomeEmail}
            onChange={() => setSendWelcomeEmail((prev) => !prev)}
          />
        </div>
      </div>

      {!sendWelcomeEmail && (
        <div className="flex my-5">
          {/* Password */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              autoComplete="new-password"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {/* Confirm Password */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              autoComplete="new-password"
              type="password"
              name="confirm-password"
              label="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default WorkspaceImportUserModal;
