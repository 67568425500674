import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
// import BulkUpload2 from "src/components/Shared/MultiColumnLayout/TabContent/BulkUpload2";
import { H2, H3 } from "src/components/Shared/Text/Headers";
import UserImportDataset from "src/components/Site/UserImportDataset";
import BulkUpload from "src/components/Users/Add/ImportUsers/BulkUpload";
import CSVFormat from "src/components/Users/Add/ImportUsers/CSVFormat";
import { apiUrl } from "src/config/host";

const ImportUsers = () => {
  const downloadCSVUserData = async () => {
    try {
      const res = await fetchData("GET", `${apiUrl}/users/csv-user-download`);
      if (res.status === 200) {
        // Convert the response data into a Blob
        const blob = await res.blob();

        // Create a Blob URL for the CSV data
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "user_permission_output.csv"; // Set the desired file name

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Clean up the Blob URL after download
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Server responded with an error:", res.statusText);
        // Handle the error or display a message to the user
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
      // Handle the error or display a message to the user
    }
  };

  return (
    <div className="w-full grid gap-y-6">
      <Section>
        <H2 caption="Manage all users from one DOMO dataset in the cloud.">Import User Data</H2>
        <div className="mt-4 grid gap-y-6">
          <div className="w-full">
            <H3 caption="Upload users's from a locally stored CSV file.">Bulk Upload</H3>
            <BulkUpload />
            {/* <BulkUpload2 /> */}
          </div>
          <hr className="w-full" />
          <div className="w-full relative">
            <H3 caption="Manage all users from one DOMO dataset in the cloud.">DOMO Dataset Upload</H3>
            <UserImportDataset />
          </div>
        </div>
      </Section>
      <Section>
        <H2 caption="Manage all users from one DOMO dataset in the cloud.">Export Data</H2>
        <div className="mt-4 w-full relative grid gap-y-12">
          <div className="relative w-full">
            <H3 caption="Download the schema for CSV upload via manual or API connection.">Schema Download</H3>
            <CSVFormat className="pt-2" />
          </div>
          <div className="relative w-full flex justify-between">
            <H3 caption="Create a CSV export of all current permission assignment.">Download User Data</H3>
            <div className="min-w-[140px] flex items-center justify-end">
              <Button
                disabled={true}
                onClick={downloadCSVUserData}>
                Download CSV
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ImportUsers;
