import { classNames } from "src/helpers/classNames";

const H1 = ({ className = "", children, item = { defaultCss: "", onHoverCss: "", onClick: null } }) => {
  return (
    <h1 className={`font-semibold flex items-center gap-x-2 text-3xl md:mb-6 text-gray-700 ${className}`}>
      {item.icon ? (
        <item.icon
          onClick={item.onClick}
          className={classNames("h-8 w-8", item.defaultCss, item.onHoverCss)}
        />
      ) : null}
      {children}
    </h1>
  );
};

const H2 = ({ className = "", children }) => {
  return (
    <div className="-ml-4 md:mb-4 flex">
      <h2 className={`font-semibold text-2xl bg-gray-100/50 rounded py-1 px-4 text-gray-500 ${className}`}>{children}</h2>
    </div>
  );
};

const H3 = ({ className = "", caption = "", children }) => {
  return (
    <div className="sm:my-2 mb-4 w-full flex flex-col px-4 sm:px-0">
      <h3 className={`pb-.5 text-xl font-medium text-gray-600 ${className}`}>{children}</h3>
      {caption && <p className="text-md text-gray-400">{caption}</p>}
    </div>
  );
};

const H4 = ({ className = "", caption = "", children }) => {
  return (
    <div className="ml-3 -mt-[2px] text-sm text-gray-600">
      <h4 className={className}>{children}</h4>
      <p className="p-0 text-gray-400/80">{caption}</p>
    </div>
  );
};

export { H1, H2, H3, H4 };
