import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { noLogo } from "src/config/host";

const Logo = ({ children, ...props }) => {
  const [showImage, setShowImage] = useState(true)
  const [imageLogo, setImageLogo] = useState(null);

  useEffect(() => {
    if (props?.workspaceDetails?._id && props?.me?.type === "user" && !props?.workspaceDetails?.use_global_settings) {
      if (props?.workspaceDetails?.image_logo) {
        setImageLogo(props?.workspaceDetails?.image_logo + "?" + Date.now());
      } else {
        // setImageLogo(noLogo);
      }
    } else {
      if (props.site?.image_logo) {
        setImageLogo(props.site?.image_logo);
      } else {
        // setImageLogo(noLogo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.workspaceDetails?.image_logo, props.site?.image_logo, props?.me?.type]);

  return (
    <div className="flex items-center h-[56px] flex-shrink-0">
      {children}
      <div className="transition-all duration-75 min-w-[130px]">
        <Link to="/workspaces">
          {
            showImage &&
            <img
              className="h-10 sm:h-12 w-auto"
              src={`${imageLogo}?${new Date().getTime()}`}
              alt="Site logo"
              onError={() => setShowImage(false)}
            />
          }
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Logo);
