import React, { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import { connect } from "react-redux";
import { useParams } from "react-router";
import WorkspaceIntegrationClientsItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClientsItem";
import WorkspaceIntegrationClientsInstanceItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClientsInstanceItem";
import { deletePageCredentialOptions } from "src/actions/page";
import { workspaceIntegrationCredentialCreate, workspaceIntegrationCredentialManage } from "src/actions/workspace";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { KeyIcon } from "@heroicons/react/24/outline";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import NoneFound from "src/components/Shared/NoData/NoneFound";

const WorkspaceIntegrationClients = ({ user, workspaceId, deletePageCredentialOptions, integration, workspaceDetails, ...props }) => {
  const { id } = useParams();

  const [credentials, setCredentials] = useState([]);

  const [viewOnly, setViewOnly] = useState(true);

  useEffect(() => {
    let authResponse = authorizeUserComponentAccess(user, workspaceId, "integration", ["create"]);
    setViewOnly(!authResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, workspaceId]);

  useEffect(() => {
    setCredentials(integration?.page_credentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration]);

  useEffect(() => {
    const manageCredential = async () => {
      try {
        await props.workspaceIntegrationCredentialManage({ credentials: credentials, workspace_id: workspaceId, integration_id: integration._id });
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };
    manageCredential();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const addCredential = async () => {
    try {
      const data = await props.workspaceIntegrationCredentialCreate({ integration_id: integration._id, workspace_id: workspaceId, is_global: props.is_global });
      setCredentials([...credentials, data]);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const removePageCredential = async (id) => {
    const ac = new AbortController();
    try {
      setCredentials(credentials.filter((cred) => cred._id !== id));
      await deletePageCredentialOptions({ credential_id: id, workspace_id: workspaceId }, ac.signal);
    } catch (error) {}
    return () => ac.abort();
  };

  if (id && !workspaceDetails?._id) {
    return <></>;
  }

  return (
    <>
      <div className="relative">
        <div className="w-full justify-between flex items-center gap-4">
          <div className="text-base font-medium text-gray-400">Available credentials</div>
          {workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && !viewOnly && authorizeUserComponentAccess(user, workspaceId, "integration", ["create"]) && (
            <Button
              version="gray"
              onClick={addCredential}>
              <IconsWithPlus
                strokeColor={"stroke-gray-400"}
                item={{ icon: KeyIcon }}
              />
            </Button>
          )}
        </div>
        <div className="space-y-3 mt-2 bg-white border-gray-200 rounded">
          {credentials?.length > 0 ? (
            credentials.map((flt, index) => {
              return workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" ? (
                <WorkspaceIntegrationClientsItem
                  key={integration._id + "_" + index}
                  user={user}
                  workspaceId={workspaceId}
                  state={flt}
                  setState={(e) => {
                    setCredentials(
                      credentials.map((fl, i) => {
                        if (index === i) {
                          return e;
                        } else {
                          return fl;
                        }
                      })
                    );
                  }}
                  workspaceType={workspaceDetails}
                  removePageCredential={removePageCredential}
                />
              ) : (
                <WorkspaceIntegrationClientsInstanceItem
                  key={integration._id + "_" + index}
                  user={user}
                  workspaceId={workspaceId}
                  state={flt}
                  setState={(e) => {
                    setCredentials(
                      credentials.map((fl, i) => {
                        if (index === i) {
                          return e;
                        } else {
                          return fl;
                        }
                      })
                    );
                  }}
                />
              );
            })
          ) : (
            <NoneFound />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspace: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialCreate, workspaceIntegrationCredentialManage, deletePageCredentialOptions })(WorkspaceIntegrationClients);
