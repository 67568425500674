import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { H3 } from "src/components/Shared/Text/Headers";
import SiteColorItem from "src/components/Site/SiteColorItem";
import useFetch from "src/hooks/useFetch";
import Section from "src/components/Shared/Containers/Section";

const SSOCustomizations = () => {
  const {
    response: { data: site },
    refreshData: refreshSite,
  } = useFetch("/site-settings/unprotected");

  const [ssoButtonText, setSsoButtonText] = useState(site?.sso_button_text || "");
  const [ssoButtonColor, setSsoButtonColor] = useState(site?.sso_button_color || "");
  const [ssoButtonTextColor, setSSOButtonTextColor] = useState(site?.sso_button_text_color || "");
  const [ssoAdminRedirect, setSsoAdminRedirect] = useState(site?.sso_admin_redirect || false);
  const [adminDomain, setAdminDomain] = useState(site?.sso_default_relay_url || "");

  const onSubmit = async () => {
    try {
      let body = {
        sso_default_relay_url: adminDomain,
        sso_button_text: ssoButtonText,
        sso_button_color: ssoButtonColor,
        sso_button_text_color: ssoButtonTextColor,
        sso_admin_redirect: ssoAdminRedirect,
      };
      await apiRequest("put", "/site-settings", { body });

      refreshSite();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setPageData = () => {
    if (site?._id) {
      setSsoButtonText(site.sso_button_text || "");
      setSsoButtonColor(site.sso_button_color || "");
      setSSOButtonTextColor(site.sso_button_text_color || "");
      setAdminDomain(site?.sso_default_relay_url || "");
      setSsoAdminRedirect(site.sso_admin_redirect || false);
    }
  };

  const onClear = () => {
    setPageData();
  };

  useEffect(() => {
    setPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  return (
    <Section className="gap-y-8">
      <div className="w-full">
        <H3 caption="Configure the SSO button.">SSO Styling</H3>
        <div className="flex flex-col sm:flex-row md:items-center w-full gap-y-6 gap-x-4">
          <div className="w-[200px] mb-10 sm:mb-0">
            <Input
              name="sso-button-text"
              label="Button Text"
              value={ssoButtonText || site.sso_button_text}
              onChange={(e) => setSsoButtonText(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="flex space-x-4">
              <div
                className="mt-8 sm:mt-6 min-w-[130px] bg-gray-400 h-10 px-4 rounded-md flex items-center justify-center"
                style={{ backgroundColor: ssoButtonColor || site.sso_button_color }}>
                <p
                  className="h-6 flex items-center justify-center rounded-md font-medium"
                  style={{ color: ssoButtonTextColor || site.sso_button_text_color }}>
                  {ssoButtonText || site.sso_button_text}
                </p>
              </div>
            </div>
            <div className="absolute z-10 bottom-11 left-0 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem
                onChange={setSsoButtonColor}
                color={ssoButtonColor || site.sso_button_color}
                name="SSO Button Background"
              />
            </div>
            <div className="absolute z-20 -bottom-16 left-[20px] bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem
                onChange={setSSOButtonTextColor}
                color={ssoButtonTextColor || site.sso_button_text_color}
                name="SSO Button Text"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-x-4 mt-20 sm:mt-4">
          <Button
            version="gray"
            onClick={onClear}
            disabled={ssoButtonText + ssoButtonTextColor + ssoButtonColor === "" || (ssoButtonText === site?.sso_button_text && ssoButtonTextColor === site?.sso_button_text_color && ssoButtonColor === site?.sso_button_color)}>
            Undo changes
          </Button>
          <Button
            onClick={onSubmit}
            disabled={ssoButtonText + ssoButtonTextColor + ssoButtonColor === "" || (ssoButtonText === site?.sso_button_text && ssoButtonTextColor === site?.sso_button_text_color && ssoButtonColor === site?.sso_button_color)}>
            Update
          </Button>
        </div>
      </div>
      <hr className="w-full" />
      <div className="w-full">
        <H3 caption="Setting up a Default Relay url is essential for the best admin proxy routed experience.">Default Relay Settings</H3>
        <div className="flex flex-wrap w-full gap-y-6 justify-between">
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="site-domain"
              label="Admin portal URL"
              value={adminDomain || site.sso_default_relay_url}
              onChange={(e) => setAdminDomain(e.target.value)}
            />
          </div>
          <div className="flex justify-end gap-x-4 items-end">
            <div className="h-10 flex gap-x-2">
              <Button
                version="gray"
                disabled={site.sso_default_relay_url === adminDomain || adminDomain === ""}
                onClick={() => setAdminDomain(site.sso_default_relay_url)}>
                Undo changes
              </Button>
              <Button
                disabled={site.sso_default_relay_url === adminDomain || adminDomain === ""}
                onClick={onSubmit}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full" />
      <div className="w-full">
        <H3 caption="When SSO is enabled users are always redirected, but choose whether the Admin portal redirects">Navigation Controls</H3>
        <div className="relative w-full sm:px-8">
          <div className="flex">
            <Toggle
              checked={ssoAdminRedirect}
              onChange={setSsoAdminRedirect}
            />
            <div className="ml-3 -mt-[2px] text-sm text-gray-600">
              <p className="p-0">Redirect "ADMIN" users to SSO login url</p>
              <p className="p-0 text-gray-400/80">Turned off by default. Verify correct SSO setup before toggling</p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-x-4 mt-4">
          <Button
            version="gray"
            onClick={onClear}
            disabled={ssoAdminRedirect === site?.sso_admin_redirect}>
            Undo changes
          </Button>
          <Button
            onClick={onSubmit}
            disabled={ssoAdminRedirect === site?.sso_admin_redirect}>
            Update
          </Button>
        </div>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SSOCustomizations);
