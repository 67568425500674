import React from "react";

export default function SingleLineCheckbox({ value, onChange = () => {}, title = "title", subtitle = "subtitle" }) {
  return (
    <div className="relative flex flex-col">
      <div className="flex justify-between text-base font-medium text-gray-700">{title}</div>
      <div className="pt-1 flex md:pt-0 items-center mt-1">
        <input
          checked={value}
          id="type"
          aria-describedby="notifications-description"
          name="type"
          type="checkbox"
          className="h-4 w-4 text-highlightColor border-gray-300 rounded"
          onChange={() => onChange(!value)}
        />
        <p className="ml-3 text-sm text-gray-400">{subtitle}</p>
      </div>
    </div>
  );
}
