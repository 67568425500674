import { ArrowPathIcon, CheckCircleIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, Cog8ToothIcon, LockClosedIcon, PaintBrushIcon, WindowIcon } from "@heroicons/react/24/outline";
import { CameraIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { apiRequest } from "src/async/apiUtils";
import BackIndicator from "src/components/Navigation/BackIndicator";
import PageList from "src/components/Pages/PageList";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Tabs from "src/components/Shared/Tabs/Tabs";
import WorkspaceGroupList from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupList";
import WorkspacePdpList from "src/components/Workspaces/Workspace/Pdp/WorkspacePdpList";
import WorkspacePermissions from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissions";
import WorkspaceSettings from "src/components/Workspaces/Workspace/Settings/WorkspaceSettings";
import WorkspaceUserList from "src/components/Workspaces/Workspace/Users/WorkspaceUsersList";
import WorkspaceLayoutStyles from "src/components/Workspaces/Workspace/WorkspaceLayoutStyles/WorkspaceLayoutStyles";
import WorkspaceTag from "src/components/Workspaces/WorkspaceTag";
import { baseUrl, noLogo } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useDebounce from "src/hooks/useDebounce";
import useFetch from "src/hooks/useFetch";
import { useLoadData } from "src/hooks/useLoadData";
import { getWorkspaceDetails } from "src/actions/workspace";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

const WorkspaceEdit = ({ ...props }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useState({});
  const [reduxWorkspaceLoading, setReduxWorkspaceLoading] = useState(true);
  const [workspaceLoaded, setWorkspaceLoaded] = useState({});
  const [tabs, setTabs] = useState([]);
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");

  const [tagList, setTagList] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryValue = queryParams.get("tab");

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const handleWorkspaceUpdate = async (name = " ") => {
    await apiRequest("put", `/workspaces/${id}/settings`, { body: { name } });
  };

  const [debouncedNameUpdate, debounceNameStatus] = useDebounce(handleWorkspaceUpdate, 500);

  const handleChange = async (e) => {
    setName(e.target.value);
    debouncedNameUpdate(e.target.value);
  };

  const handleTag = async (e) => {
    if (e.code === "Enter" || e.code === "Comma" || e.submit === true) {
      if (e.submit !== true) {
        e.preventDefault();
      }
      try {
        if (tagList.findIndex((tg) => tg.toLowerCase() === tag.toLowerCase()) === -1) {
          const tags = [...tagList, tag];
          setTagList(tags);
          setTag("");
          const resp = await apiRequest("put", `/workspaces/${id}/settings`, { body: { tags } });
          if (resp.statusText === "OK" || resp.statusText === 200) {
            toast.success(resp.data.message);
          } else {
            toast.error(resp.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const removeTag = async (index) => {
    try {
      tagList.splice(index, 1);
      setTagList([...tagList]);
      const resp = await apiRequest("put", `/workspaces/${id}/settings`, { body: { tags: tagList } });
      if (resp.statusText === "OK" || resp.statusText === 200) {
        toast.success(resp.data.message);
      } else {
        toast.error(resp.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (workspace) {
      setName(workspace.name || "");
      setTagList(workspace.tags || []);
    }
  }, [workspace]);

  useEffect(() => {
    if (id) {
      (async () => {
        setReduxWorkspaceLoading(true);

        try {
          await props.getWorkspaceDetails({ workspace_id: id });
        } catch (err) {
        } finally {
          setReduxWorkspaceLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (workspace?._id && props.me?._id) {
      let tabs = [];
      if (authorizeUserComponentAccess(props.me, id, "page", [], true)) {
        tabs.push({
          name: "Pages",
          icon: WindowIcon,
          component: (
            <PageList
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "page", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "user", [], true)) {
        tabs.push({
          name: "Users",
          icon: ClipboardDocumentListIcon,
          component: (
            <WorkspaceUserList
              workspaceId={id}
              workspaceDetails={workspace}
              pageName={"workspace"}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "user", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "group", [], true)) {
        tabs.push({
          name: "Groups",
          icon: ClipboardDocumentListIcon,
          component: (
            <WorkspaceGroupList
              workspaceId={id}
              workspaceDetails={workspace}
              pageName={"workspace"}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "group", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "permission", [], true) && workspace.workspace_type !== "JWT_FULL_EMBED") {
        tabs.push({
          name: "Editor Permissions",
          icon: ClipboardDocumentCheckIcon,
          component: (
            <WorkspacePermissions
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "permission", [], true) || workspace.workspace_type === "JWT_FULL_EMBED",
        });
      }
      if (workspace.workspace_type !== "IFRAME_EMBED") {
        tabs.push({
          name: "PDP",
          icon: LockClosedIcon,
          component: (
            <WorkspacePdpList
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: workspace.workspace_type === "IFRAME_EMBED",
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "layout", [], true)) {
        tabs.push({
          name: "Layout & Styles",
          icon: PaintBrushIcon,
          component: (
            <WorkspaceLayoutStyles
              workspaceId={id}
              setWorkspace={setWorkspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "layout", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "integration", true)) {
        tabs.push({
          name: "Settings",
          icon: Cog8ToothIcon,
          component: (
            <WorkspaceSettings
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "integration", true),
        });
      }
      setTabs(tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me?._id, workspace?._id]);

  useLoadData("get", `/workspaces/${id}`, {}, (res) => setWorkspace(res.data), workspaceLoaded, setWorkspaceLoaded);

  const loadingColors = {
    IDLE: "group-focus:text-transparent",
    WAITING: "group-focus:text-gray-700/50",
    DONE: "group-focus:text-green-700/50",
  };

  // If you change the layout of workspace and then goto the groups tab, then page link automatically changed depend on layout
  useEffect(() => {
    setWorkspaceLoaded(false);
  }, [location]);

  if (queryValue && !queryValue.toLowerCase().includes("editor permissions") && !queryValue.toLowerCase().includes("workspace") && !queryValue.toLowerCase().includes("layout") && !queryValue.toLowerCase().includes("setting") && !queryValue.toLowerCase().includes("pdp")) {
    const existsPath = props.menus.some((obj) => {
      return obj.path.includes(queryValue.toLowerCase());
    });
    // const existsPath = props.menus.some(obj => obj.path === queryParams.get("tab"));
    if (Object.keys(props.menus).length && !existsPath) {
      navigate("/");
    }
  }

  if (!props?.me?._id) return <></>;
  if (props?.me?.type === "editor" && !props.me.workspaces.includes(id)) {
    if (!Array.isArray(props.me.workspaces)) {
      window.location.href = "/workspaces";
    }

    if (!props.me.workspaces.length) {
      window.location.href = "/workspaces";
    }

    const workspaceIds = props.me.workspaces.map((item) => item._id);

    if (!workspaceIds.includes(id)) {
      window.location.href = "/workspaces";
    }
  }

  if (reduxWorkspaceLoading) {
    return <Preloader />;
  }

  return (
    <div className="h-full w-full">
      <div className="flex h-full w-full items-center justify-between mb-4 md:mb-6">
        <div className="relative flex items-center gap-x-2 sm:gap-x-4">
          {(props?.me?.type === "admin" || props?.me?.workspaces > 1) && workspace._id && (
            <div>
              <BackIndicator />
            </div>
          )}
          {workspace && (
            <>
              <div
                onClick={() => {
                  if (authorizeUserComponentAccess(props.me, id, "layout", [], true)) {
                    navigate({ pathname: location.pathname, search: "tab=Layout+%26+Styles" });
                  }
                }}
                style={{ backgroundColor: props?.workspaceDetails?.top_bar_color ? props?.workspaceDetails?.top_bar_color : workspace.top_bar_color }}
                className={`p-1 relative group w-40 h-16 border border-gray-200/60 rounded-md bg-transparent ${authorizeUserComponentAccess(props.me, id, "layout", [], true) ? "cursor-pointer hover:border-highlightColor" : ""}`}>
                {workspace._id && (
                  <img
                    src={
                      workspace.image_logo instanceof File
                        ? URL.createObjectURL(workspace.image_logo)
                        : workspace.image_logo?.includes(".s3.")
                        ? workspace.image_logo + "?" + Date.now()
                        : workspace.image_logo?.includes("data:image/")
                        ? workspace.image_logo
                        : baseUrl + workspace.image_logo
                    }
                    alt="Logo"
                    className="min-w-[80px] sm:min-w-0 w-full h-full object-contain"
                    onError={(e) => (e.target.src = `${noLogo}`)}
                  />
                )}
                {authorizeUserComponentAccess(props.me, id, "layout", [], true) && (
                  <div className="group-hover:opacity-80 opacity-0 rounded-full bg-highlightColor transition-all duration-75 absolute -top-4 -right-4 z-20">
                    <CameraIcon className="h-7 w-7 p-1 text-white" />
                  </div>
                )}
              </div>

              <button className="group relative">
                <div className={classNames("p-1 font-light py-.5 px-1 flex gap-x-2 items-center overflow-hidden transition-all duration-75 absolute -top-1 -right-7", loadingColors[debounceNameStatus])}>
                  {debounceNameStatus === "WAITING" && (
                    <p className="rounded-full flex bg-gray-500/20 items-center">
                      <ArrowPathIcon className="h-5 w-5 animate-spin text-white" />
                    </p>
                  )}
                  {debounceNameStatus === "DONE" && (
                    <p className="rounded-full bg-green-600/40 text-white flex items-center">
                      <CheckCircleIcon className="h-5 w-5 text-white" />
                    </p>
                  )}
                </div>
                <Input
                  type="text"
                  autoComplete="on"
                  name="workspace-name"
                  disabled={!authorizeUserComponentAccess(props.me, id, "layout", ["update"], true)}
                  value={name}
                  onChange={handleChange}
                  inputClassNames={`pr-4 focus:ring-0 focus:border-highlightColor bg-transparent border border-transparent flex items-center gap-x-2 text-3xl px-2 py-1 rounded transition-all duration-75 font-thin text-gray-400 ${
                    authorizeUserComponentAccess(props.me, id, "layout", ["update"], true) ? "hover:border-gray-400/20" : ""
                  }`}
                />
              </button>
              <div className="hidden sm:flex items-center gap-4 flex-wrap">
                {tagList.length > 0 && (
                  <div className="flex items-center gap-2 flex-wrap">
                    {tagList.map((tag, index) => {
                      return (
                        <WorkspaceTag
                          index={index}
                          key={tag}
                          tag={tag}
                          onDelete={removeTag}
                        />
                      );
                    })}
                  </div>
                )}
                {workspace._id && activeSSO.isSSOActive && (
                  <div className="w-40 flex h-8 gap-x-3">
                    <Input
                      type="text"
                      autoComplete="off"
                      name="tag-name"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      onBlur={() => {
                        setTag("");
                      }}
                      onKeyDown={handleTag}
                      placeholder="+ Add tag"
                      inputClassNames="focus:ring-0 focus:border-highlightColor bg-transparent hover:border-gray-400/20 border border-transparent flex items-center gap-x-2 text-sm px-2 py-1 rounded transition-all duration-75 text-gray-600 "
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {tabs.length > 0 && <Tabs tabs={tabs} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
    menus: Object.values(state.menus),
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { getAuthorizedUser, getWorkspaceDetails })(WorkspaceEdit);
