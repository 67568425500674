import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { getDomoWorkspaceFirstPage } from "src/actions/page";
import Image from "src/components/Shared/Image";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { UserWorkspaceContext } from "src/context/UserWorkspaceContext";

function Workspaces({ user, site }) {
  const { workspaceLoading, workspaces: workspaceList } = useContext(UserWorkspaceContext);

  const replyClick = async (wrk) => {
    try {
      const response = await getDomoWorkspaceFirstPage({ clicked_id: wrk?._id, user_id: user._id });
      if (response.status === 200) {
        window.open(response.link, response.target);
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    if (workspaceList.length === 1) {
      replyClick(workspaceList[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceList.length === 1]);

  if (workspaceLoading || workspaceList.length === 1) {
    return (
      <div className="w-full h-full flex justify-center">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex">
      <div
        className="relative w-full transition-all"
        id="content">
        <div
          className="w-full py-8 min-h-[800px] px-5 bg-texture"
          id="pageContent">
          <div className="w-full max-w-3xl bg-white p-8 mx-auto my-32  rounded-lg shadow-lg">
            <div className="my-8">
              <div className="text-2xl text-gray-700 text-light text-center">Choose your workspace</div>
              <div className="mt-8 flex justify-center flex-wrap -mx-4">
                {workspaceList.map((wrk, index) => {
                  return (
                    <div
                      className="relative m-4"
                      key={index}>
                      <button
                        onClick={(e) => {
                          replyClick(wrk);
                        }}
                        style={{ backgroundColor: wrk?.top_bar_color }}
                        className="flex items-center justify-center w-32 h-32 transition-all duration-50 border-2 border-gray-200 rounded-[20px] overflow-hidden bg-white p-4 hover:border-blue-600">
                        <Image
                          className="max-h-[60px]"
                          image={wrk.image_logo || wrk.square_logo || wrk.image_favicon}
                          alt={"Workspace"}
                        />
                        {/* <img
                          className="w-full h-full max-h-[60px] object-contain"
                          src={wrk.image_logo?.includes(".s3.") ? wrk.image_logo + "?" + Date.now() : wrk.image_logo?.includes("data:image/") ? wrk.image_logo : baseUrl + wrk.image_logo}
                          onError={(e) => (e.target.src = `${noLogo}`)}
                          alt=""
                        /> */}
                      </button>
                      <div className="text-center font-medium mt-4 text-base text-gray-600">{wrk?.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(Workspaces);
