import { useEffect, useState } from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Image from "src/components/Shared/Image";

const UserPermissionTabDataItem = ({ userPermission, workspace, selectedTab }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    setImage(workspace?.workspace_id.image_logo);
  }, [workspace?.workspace_id]);

  return (
    <>
      <div className="flex items-center gap-4 py-4 even:bg-gray-50 odd:bg-white">
        <div className="w-full md:min-w-[320px]">
          <div className="flex items-center gap-3 px-4">
            <div className="w-10 h-10 min-w-[40px] min-h-[40px] hidden md:flex items-center justify-center font-bold uppercase bg-gray-200 rounded-full">
              <Image
                image={image}
                alt={"User permission tab data item"}
              />
            </div>
            <div className="w-full">
              <div className="text-gray-800 font-semibold">{workspace?.workspace_id?.name}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled
            checkboxName={selectedTab + "_read"}
            forceCheck
            isChecked={workspace[selectedTab + "_create"] || workspace[selectedTab + "_update"] || workspace[selectedTab + "_delete"]}
            users={[workspace]}
          />
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled
            checkboxName={selectedTab + "_create"}
            forceCheck
            isChecked={workspace[selectedTab + "_create"]}
            users={[workspace]}
          />
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled
            checkboxName={selectedTab + "_update"}
            forceCheck
            isChecked={workspace[selectedTab + "_update"]}
            users={[workspace]}
          />
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled
            checkboxName={selectedTab + "_delete"}
            forceCheck
            isChecked={workspace[selectedTab + "_delete"]}
            users={[workspace]}
          />
        </div>
        <div className="w-full"></div>
      </div>
    </>
  );
};

export default UserPermissionTabDataItem;
