import { useEffect, useState } from "react";

export const useDebounce = (value = "", delay, callback = () => {}) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setTimeout(() => {
        callback(value);
      }, 1);
    }, delay);

    // Cleanup function to cancel the previous setTimeout
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length, delay]);

  return debouncedValue;
};
