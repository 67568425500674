import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "src/helpers/classNames";

const TextAccordion = ({ children, headerText, headerTextPosition = "right", startOpen = false }) => {
  const [show, setShow] = useState(startOpen);

  return (
    <div className="relative rounded-xl transition-all duration-200">
      <div className={classNames("mt-5 mb-1 flex", headerTextPosition === "right" ? "justify-end" : "")}>
        <div
          onClick={() => setShow(!show)}
          className="-mt-[10px] inline-flex cursor-pointer items-center gap-x-1 rounded-md py-1 px-2 text-gray-300 transition-all duration-200 sm:hover:bg-gray-50 sm:hover:text-gray-400">
          <p className="select-none">{headerText}</p>
          <ChevronDownIcon className={classNames("h-5", show ? "" : "-rotate-90")} />
        </div>
      </div>
      <div className={classNames("overflow-hidden transition-all duration-150", show ? "border max-h-[900px] rounded-md border-gray-200 px-6 py-4 opacity-100" : "max-h-[0px] opacity-0")}>{children}</div>
    </div>
  );
};

export default TextAccordion;
