import { connect } from "react-redux";
import { CheckIcon } from "@heroicons/react/24/solid";
import { classNames } from "src/helpers/classNames";

const Checkbox = ({ checkboxName, checkboxInputClass, isChecked, checkboxLabel, checkboxLableClass, disabled = false, forceCheck = false, onChange = () => {}, siteSettings }) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center gap-3">
        <input
          type="checkbox"
          disabled={disabled}
          name={checkboxName}
          className={classNames("peer absolute top-0 left-0 w-4 h-4 min-w-[16px] min-h-[16px] opacity-0 z-10 cursor-pointer " + checkboxInputClass, disabled ? "bg-gray-300 cursor-not-allowed" : "")}
          checked={forceCheck ? isChecked : isChecked && !disabled}
          onChange={onChange}
        />
        <div
          className={classNames(
            "w-4 h-4 min-w-[16px] min-h-[16px] border border-gray-300 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:text-white",
            siteSettings?.highlight_color ? "peer-checked:bg-highlightColor peer-checked:border-highlightColor" : "peer-checked:bg-black peer-checked:border-black",
            disabled ? "bg-gray-100 cursor-not-allowed" : ""
          )}>
          <CheckIcon className="w-4 h-4" />
        </div>
        {checkboxLabel && <div className={"text-sm text-gray-500 w-full " + checkboxLableClass}>{checkboxLabel}</div>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteSettings: state.site,
  };
};

export default connect(mapStateToProps, {})(Checkbox);
