import { useEffect, useState } from "react";
import { builderQueryString } from "src/async/apiUtils";
import { apiUrl } from "src/config/host";
import axios from "axios";
import { refreshTokenVerify } from "src/actions/auth";

const useLoadData = (method = "post", url = "", payload = { body: null, queries: null }, onSuccess = null, loaded = false, setLoaded = () => {}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const loadData = async () => {
    try {
      if (!url) return

      let queryString = builderQueryString(payload.queries);

      let accessToken = localStorage.getItem("access_token");

      let headers = { "Content-Type": "application/json", Accept: "application/json" };

      if (accessToken) {
        headers = {
          ...headers,
          Authorization: accessToken,
        };
      }
      let request = axios.create({
        baseURL: apiUrl,
        headers,
      });

      let res = await request[method](`${url}${queryString}`, payload.body);

      if (res.status === 401 && res?.data?.invalid) {
        localStorage.removeItem('userId')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        window.location.href = '/login'
        return
      }

      if (res.status === 401 && res?.data?.expired) {
        const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

        if (refreshTokenRes.status === 200) {
          headers.Authorization = refreshTokenData.refresh_token

          request = axios.create({
            baseURL: apiUrl,
            headers,
          });
    
          res = await request[method](`${url}${queryString}`, payload.body);
        } 
      }

      if (res.status === 200) {
        setFirstLoad(false);
        setLoaded(true);
        if (onSuccess) {
          return onSuccess(res.data);
        }
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      if (error?.response?.status === 401 && error?.response?.data?.invalid) {
        localStorage.removeItem('userId')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        window.location.href = '/login'
        return
      }

      if (error?.response?.status === 401 && error?.response?.data?.expired) {
        const queryString = builderQueryString(payload.queries);

        const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

        if (refreshTokenRes.status === 200) {
          const headers =  {
            "Content-Type": "application/json", Accept: "application/json" 
          };
  
          headers.Authorization = refreshTokenData.refresh_token
  
          const request = axios.create({
            baseURL: apiUrl,
            headers,
          });
  
          const res = await request[method](`${url}${queryString}`, payload.body);
  
          if (res.status === 200) {
            setFirstLoad(false);
            setLoaded(true);
            if (onSuccess) {
              return onSuccess(res.data);
            }
          } else {
            throw new Error(res.message);
          }
        } else {
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('refresh_token')

          // window.location.href = '/login'

          throw new Error(error.message);
        }
      } else {
        throw new Error(error.message);
      }
    }
  };

  useEffect(() => {
    if (firstLoad || !loaded) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, firstLoad]);
};

export { useLoadData };
