import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import InformationHoverIcon from "src/components/Shared/InformationHoverIcon";
import { manageSiteSettings } from "src/actions/site";
import { toast } from "react-toastify";
import TextCode from "src/components/Shared/Text/TextCode";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";

const SSOAttributeMapper = ({ text = "", options = [], onSetOption = () => {}, startIndex = -1 }) => {
  return (
    <div className="pl-7 py-2 flex flex-wrap items-end space-x-3 relative">
      <div className="flex items-center gap-x-1 relative text-sm font-medium text-gray-700">
        Add <TextCode text={`${text}s`} /> where
      </div>
      <div className="relative w-48">
        <InformationHoverIcon location="-top-4 -right-4">
          If the value for the {text} is comma seperated then each CSV value will be evaluated and checked against existing {text} tags.
        </InformationHoverIcon>
        <SelectMenu
          label=""
          startIndex={startIndex}
          options={options}
          setOption={onSetOption}
        />
      </div>
      <div className="relative py-2.5 text-sm font-medium text-gray-700">
        is included in <TextCode text={`${text}.tags`} />
      </div>
    </div>
  );
};

const SSOServiceAttribute = ({ site, manageSiteSettings }) => {
  const [dynamicAttributes, setDynamicAttributes] = useState([]);

  const trustedAttributes = ["user.firstName", "user.lastName", "user.email", "user.username", "user.company", "user.department"];
  const [ssoMapAttribute, setSsoMapAttribute] = useState(false);
  const [ssoWorkspaceTrustedAttribute, setSsoWorkspaceTrustedAttribute] = useState("");
  const [ssoWorkspaceGroupTrustedAttribute, setSsoWorkspaceGroupTrustedAttribute] = useState("");
  const trustedAttributeArr = trustedAttributes.map((trustedAttribute) => {
    return {
      key: trustedAttribute,
      value: trustedAttribute,
    };
  });

  const loadDynamicAttributes = useCallback(() => {
    (async () => {
      try {
        const res = await fetchData("GET", `${apiUrl}/users/custom-fields`);
        const resData = await res.json();

        if (resData.status === 200 && resData.data) {
          setDynamicAttributes(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadDynamicAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allAttributes = [
    ...trustedAttributeArr,
    ...dynamicAttributes.map((item) => ({
      key: `user.${item.code}`,
      value: `user.${item.code}`,
    })),
  ];

  useEffect(() => {
    if (site?._id) {
      setSsoMapAttribute(site?.sso_map_attribute);
      setSsoWorkspaceTrustedAttribute(site?.sso_workspace_trusted_attribute);
      setSsoWorkspaceGroupTrustedAttribute(site?.sso_workspace_group_trusted_attribute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const updateSSORole = async () => {
    try {
      const message = await manageSiteSettings({
        sso_map_attribute: ssoMapAttribute,
        sso_workspace_trusted_attribute: ssoWorkspaceTrustedAttribute,
        sso_workspace_group_trusted_attribute: ssoWorkspaceGroupTrustedAttribute,
      });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="shadow bg-white rounded-md mt-4 p-6 flex flex-col gap-y-3">
        <h5 className="text-base font-medium mb-4 flex gap-x-8">
          Map SSO Attributes
          <Toggle
            checked={ssoMapAttribute}
            onChange={setSsoMapAttribute}
          />
        </h5>
        <div className="w-full h-[1px]" />
        <div className="w-full flex flex-col rounded-md relative">
          <div className={`${!ssoMapAttribute ? "block opacity-60 z-50 cursor-not-allowed" : "opacity-0 z-0"} w-full h-full bg-gray-100 transition-all duration-150 rounded-md opacity-0 absolute`} />
          <div className="w-full h-full py-5 border border-gray-200 rounded-md">
            <SSOAttributeMapper
              options={allAttributes}
              onSetOption={(option) => setSsoWorkspaceTrustedAttribute(option.key)}
              startIndex={allAttributes.findIndex((item) => item.key === ssoWorkspaceTrustedAttribute)}
              text="workspace"
            />
            <div className="relative ml-20 pl-7 pt-4">
              <div className="top-6 left-2 absolute flex justify-center items-center h-10 w-6">
                <div className="h-[30px] w-[2px] bg-gray-200 rounded-full absolute top-[-14px] left-[2px]"></div>
                <div className="h-[2px] w-[16px] bg-gray-200 rounded-full absolute top-[16px] left-[2px]"></div>
                <div className="h-[2px] w-[12px] bg-gray-200 rounded-full rotate-45 absolute top-[12px] left-[8px]"></div>
                <div className="h-[2px] w-[12px] bg-gray-200 rounded-full -rotate-45 absolute top-[20px] left-[8px]"></div>
              </div>
              <SSOAttributeMapper
                options={allAttributes}
                onSetOption={(option) => setSsoWorkspaceGroupTrustedAttribute(option.key)}
                startIndex={allAttributes.findIndex((item) => item.key === ssoWorkspaceGroupTrustedAttribute)}
                text="group"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center w-full pt-6 space-x-4">
        <Button version="gray">Cancel</Button>
        <Button onClick={updateSSORole}>Save</Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { manageSiteSettings })(SSOServiceAttribute);
