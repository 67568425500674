import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageUserStatus } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import RadioGroup from "src/components/Shared/Forms/Inputs/RadioGroup";
import SingleLineCheckbox from "src/components/Shared/Forms/Inputs/SingleLineCheckbox";
import { H3 } from "src/components/Shared/Text/Headers";
import ProfileImage from "src/components/Users/Edit/ViewProfile/ProfileImage";
import { classNames } from "src/helpers/classNames";

const EditUserForm = ({
  isAdmin,
  setIsAdmin,
  manageUserStatus,
  name,
  password,
  confirm_password,
  email,
  active_status,
  image,
  notifications,
  user = {},
  setUser = () => {},
  workspaces = [],
  groups = [],
  customFields = [],
  setCustomFields = () => {},
  setWorkspaces = () => {},
  setGroups = () => {},
  groupsLoaded = false,
  clearState =  0,
  me,
  ...props
}) => {
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [disableResendEmailButton, setDisableResendEmailButton] = useState(false);
  const [passwordUpdateType, setPasswordUpdateType] = useState("reset-email");
  const [disableUserStatusChange, setDisableUserStatusChange] = useState(false)

  const userStatusChange = async () => {
    setDisableUserStatusChange(true)

    try {
      await manageUserStatus(user)
    } catch (err) {} finally {
      setDisableUserStatusChange(false)
    }
  }

  const sendResendEmail = async () => {
    setDisableResendEmailButton(true);

    try {
      const res = await apiRequest("POST", `/users/${user?._id}/send-reset-email`, {
        body: {},
        queries: undefined,
      });
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableResendEmailButton(false);
    }
  };

  const updateCustomFields = (index, value) => {
    setCustomFields(fields => fields.map((field, i) => i !== index ? field : {
      ...field,
      value
    }))
  }

  useEffect(() => {
    if (clearState) {
      setShowPasswordUpdate(false)
      setDisableResendEmailButton(false)
      setPasswordUpdateType("reset-email")
      setDisableUserStatusChange(false)
    }
  }, [ clearState ])

  return (
    <Section>
      <H3 caption="Manage individual account display information.">Edit account</H3>
      <div className="mt-4 flex flex-wrap w-full gap-y-4">
        {/* Photo */}
        <div className="w-full px-2 flex items-center gap-5 justify-between relative">
          {useCallback(
            () => (
              <ProfileImage
                user={user}
                image={image}
                setImage={(value) => setUser("image", value)}
                isFormdataValue={true}
              />
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [user._id, image, setUser]
          )()}
          {!["editor", "user"].includes(me?.type) && typeof user?.active_status === 'boolean' && (
            <div className="absolute top-1 right-1 h-full flex items-start">
              <div>
                <button
                  onClick={async () => {
                    await userStatusChange()
                    setUser("active_status", !active_status)
                  }}
                  disabled={disableUserStatusChange}
                  className={classNames("flex w-[52px] justify-center rounded-full px-2 text-xs font-semibold leading-5", active_status ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}
                >
                  {active_status ? "Active" : "Inactive"}
                </button>
              </div>
            </div>
          )}
        </div>
        {/* Name */}
        <div className="sm:w-1/2 w-full px-2">
          <Input
            name="full-name"
            label="Full name"
            type="text"
            value={name}
            onChange={(e) => setUser("name", e.target.value)}
          />
        </div>
        {!["editor", "user"].includes(me?.type) && (
          <>
            {/* Email */}
            <div className="sm:w-1/2 w-full px-2">
              <Input
                name="email"
                label="Email"
                value={email}
                onChange={(e) => setUser("email", e.target.value)}
              />
            </div>
            {customFields.length > 0 &&
              customFields.map((field, i) => (
                <div className="sm:w-1/2 w-full px-2" key={field._id}>
                  <Input
                    name={field.label}
                    label={field.label}
                    placeholder={field.placeholder || field.label}
                    value={field.value || ''}
                    onChange={(e) => updateCustomFields(i, e.target.value)}
                  />
                </div>
              ))}
            <div className="w-full">
              <TextAccordion
                headerText="Advanced features"
                headerTextPosition="left">
                <div className="grid gap-y-4">
                  <div className="sm:w-1/2 w-full">
                    {/* Admin status */}
                    <SingleLineCheckbox
                      value={isAdmin}
                      onChange={setIsAdmin}
                      title="Assign admin"
                      subtitle="You are assigning admin status by selecting this box."
                    />
                  </div>
                  <div>
                    <SingleLineCheckbox
                      value={showPasswordUpdate}
                      onChange={setShowPasswordUpdate}
                      title="Reset password"
                      subtitle="You may either send a reset email or manually set the password."
                    />
                    <div className={classNames("ml-5 w-full transition-all duration-150", showPasswordUpdate ? "mt-4 max-h-[700px]" : "mt-0 max-h-[0px] overflow-hidden")}>
                      <RadioGroup
                        key={passwordUpdateType}
                        diabled={!showPasswordUpdate}
                        title="Manage password reset"
                        subtitle="Disabling this will allow you to manually enter a password"
                        options={[
                          { id: "reset-email", title: "Send reset email" },
                          { id: "manual", title: "Manually set password" },
                        ]}
                        defaultChecked={passwordUpdateType}
                        onChange={(id) => setPasswordUpdateType(id)}
                        layout="horizontal"
                      />
                      <div className="h-20 mt-4">
                        {passwordUpdateType === "reset-email" ? (
                          <div className="pt-1">
                            <Button
                              version="secondary"
                              disabled={disableResendEmailButton}
                              onClick={sendResendEmail}>
                              Send email
                            </Button>
                          </div>
                        ) : (
                          <div className="flex flex-col md:flex-row w-full gap-y-4 gap-x-4 pr-6">
                            {/* Password */}
                            <div className="sm:w-1/2 w-full">
                              <Input
                                autoComplete="new-password"
                                type="password"
                                name="password"
                                label="Password"
                                value={password}
                                onChange={(e) => {
                                  if (!/\s/.test(e.target.value)) {
                                    setUser("password", e.target.value);
                                  }
                                }}
                              />
                            </div>
                            {/* Confirm Password */}
                            <div className="sm:w-1/2 w-full">
                              <Input
                                autoComplete="new-password"
                                type="password"
                                name="confirm-password"
                                label="Confirm password"
                                value={confirm_password}
                                onChange={(e) => {
                                  if (!/\s/.test(e.target.value)) {
                                    setUser("confirm_password", e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TextAccordion>
            </div>
          </>
        )}
      </div>
    </Section>
  );
};
const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageUserStatus })(EditUserForm);
