import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { classNames } from "src/helpers/classNames";

const QuickMenu = ({ disabled = false, position = "LEFT", items = [], icon = null }) => {
  return (
    <>
      {disabled ? (
        <div className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
          <span className="sr-only">Open menu</span>
          {icon ? (
            icon
          ) : (
            <div className="h-8 w-5 rounded-full text-gray-400 cursor-not-allowed transition-colors duration-100 flex items-center justify-center">
              <EllipsisVerticalIcon className="w-5 h-5" />
            </div>
          )}
        </div>
      ) : (
        <Menu
          as="div"
          className="relative">
          <div>
            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
              <span className="sr-only">Open menu</span>
              {icon ? (
                icon
              ) : (
                <div className="py-3 px-1 rounded-full hover:bg-gray-100 transition-colors duration-100 flex items-center justify-center">
                  <EllipsisVerticalIcon className="w-5 h-5" />
                </div>
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className={`z-50 absolute ${position === "RIGHT" ? "left-0" : "right-0 origin-top-right"} mt-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
              {items.map((item, i) =>
                item.type === "hr" ? (
                  <div
                    key={"hr-" + i}
                    className={classNames(item?.hideWhen ? "hidden" : "", "w-full my-1 h-[1px] bg-gray-100")}></div>
                ) : (
                  <Menu.Item key={item.name}>
                    <button
                      type="button"
                      className={classNames(item?.hideWhen ? "hidden" : "", "relative flex px-4 py-2 text-sm w-full text-left", item.color ? item.color : "hover:text-highlightColor text-gray-700")}
                      onClick={item.onClick}>
                      {item.name} {item?.isBeta && <div className="absolute right-2 top-[8px] rounded-full text-gray-400 bg-gray-50 px-2 py-.5 ">Beta</div>}
                    </button>
                  </Menu.Item>
                )
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  );
};

export default QuickMenu;
