import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { replacePrivateStringWithStars } from "src/helpers/replacePrivateStringWithStars";
import useFetch from "src/hooks/useFetch";
import * as Yup from "yup";
import Section from "src/components/Shared/Containers/Section";
import { H3, H4 } from "src/components/Shared/Text/Headers";
import SiteEmailManageModal from "src/components/Site/SiteEmailModal/SiteEmailManageModal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import TextAccordion from "../Shared/Accordions/TextAccordion";

const SiteEmailManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [templateData, setTemplateData] = useState({});
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [isEmailSendLoading, setIsEmailSendLoading] = useState(false);
  const [isEmailSendDisabled, setIsEmailSendDisabled] = useState(true);
  const [email, setEmail] = useState("");

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const {
    response: { data: smtpServer = {} },
    refreshData: refreshSite,
  } = useFetch("/site-settings/smtp-server");

  const {
    response: { data: emailTemplates = [], meta },
    refreshData: refreshTemplateSite,
  } = useFetch("/site-settings/email-template", {
    query: {
      limit,
      offset,
    },
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    let body = {
      site_email: values.siteEmail,
      smtp_email: values.smtpEmail,
      smtp_host: values.smtpHost,
      smtp_user: values.smtpUser,
      smtp_port: values.smtpPort,
      smtp_secure: values.smtpSecure,
    };

    if (values.smtpPassword) {
      body = { ...body, smtp_password: values.smtpPassword };
    }
    try {
      await apiRequest("PUT", "/site-settings", { body }, { showToastMessage: true });
      refreshSite();
    } catch (error) {
    } finally {
      setIsDisabled(true);
      setIsLoading(false);
    }
  };

  const sendTestEmail = async () => {
    setIsEmailSendLoading(true);
    setIsEmailSendDisabled(true);

    let body = {
      email,
    };
    try {
      await apiRequest("PUT", "/send/test-email", { body }, { showToastMessage: true });
      setEmail("");
    } catch (error) {
    } finally {
      setIsEmailSendDisabled(true);
      setIsEmailSendLoading(false);
    }
  };

  const onClear = () => {
    setIsDisabled(true);
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshTemplateSite();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  return (
    <div className="grid gap-y-4">
      <Section className="grid gap-y-8">
        <H3 caption="Customize your email templates so your clients recieve copy and branding that matches your company.">Email Templates</H3>
        <div>
          <div className="grid gap-y-2">
            {Array.isArray(emailTemplates) &&
              emailTemplates.length > 0 &&
              emailTemplates.map((item) => (
                <div
                  key={item._id}
                  className="border rounded py-1.5 px-3 pl-5 flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="py-1">
                      <span className="text-gray-500">Subject:</span> {item.subject}
                    </div>
                    <div className="py-1 text-xs text-gray-300">{item.code}</div>
                  </div>

                  <button
                    className="py-1 px-2 rounded-md text-highlightColor"
                    onClick={() => {
                      setTemplateData(item);
                      setOpenTemplateModal(true);
                    }}>
                    Edit
                  </button>
                </div>
              ))}
          </div>
          <PaginationFooter
            itemName="Email"
            limit={limit}
            offset={offset}
            count={meta?.count}
            onChange={handlePaginationChange}
          />
        </div>
      </Section>

      <Section>
        <div className="grid gap-y-8">
          <H3 caption="Adjust settings to allow for your clients to recieve emails from your domain.">SMTP Server Settings</H3>
          <Formik
            enableReinitialize={true}
            initialValues={{
              siteEmail: smtpServer?.site_email || "",
              smtpEmail: smtpServer?.smtp_email || "",
              smtpHost: smtpServer?.smtp_host || "",
              smtpUser: smtpServer?.smtp_user || "",
              smtpPassword: "",
              smtpPort: smtpServer?.smtp_port || "",
              smtpSecure: smtpServer?.smtp_secure || true,
              email: email,
            }}
            validationSchema={Yup.object({
              siteEmail: Yup.string().required("Required"),
              smtpEmail: Yup.string().required("Required"),
              smtpHost: Yup.string().required("Required"),
              smtpUser: Yup.string().required("Required"),
              smtpPort: Yup.number().required("Required"),
              smtpSecure: Yup.boolean().required("Required"),
            })}
            onSubmit={async (values) => {
              onSubmit(values);
            }}>
            {({ values, errors, touched }) => {
              const handleOnChange = (e) => {
                if (e.target.name === "email") {
                  setEmail(e.target.value);
                  setIsEmailSendDisabled(e.target.value ? false : true);
                } else {
                  setIsDisabled(false);
                }
              };
              return (
                <Form onChange={handleOnChange}>
                  <div className="grid space-y-8">
                    <div className="flex flex-wrap justify-between w-full">
                      <div className="flex flex-wrap w-full gap-y-6">
                        {/* Contact email */}
                        <div className="w-full flex flex-col sm:flex-row gap-3">
                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="smtpEmail"
                              label="SMTP email"
                            />
                          </div>

                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="siteEmail"
                              label={
                                <p>
                                  Support contact <span className="pl-2 text-gray-300">*optional email</span>
                                </p>
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row gap-3">
                          {/* SMTP user */}
                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="smtpUser"
                              label="User"
                            />
                          </div>
                          {/* SMTP password */}
                          <div className="sm:w-1/2 w-full">
                            <Input
                              placeholder={replacePrivateStringWithStars(smtpServer.smtp_password)}
                              name="smtpPassword"
                              label="Password"
                              type="password"
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row gap-3">
                          {/* SMTP host */}
                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="smtpHost"
                              label="Host"
                            />
                          </div>
                          {/* SMTP port */}
                          <div className="relative sm:w-1/2 w-full">
                            {/* SMTP secure */}
                            <div className="absolute z-30 flex items-center gap-x-2 ml-2 top-0 right-4 text-gray-700 opacity-70 text-xs cursor-pointer">
                              <input
                                className="rounded text-gray-300 focus:ring-highlightColor cursor-pointer"
                                type="checkbox"
                                checked={values.smtpSecure}
                                name="smtpSecure"
                              />
                              <label className="cursor-pointer">SMTP Secure</label>
                            </div>
                            <div className="w-full">
                              <Input
                                name="smtpPort"
                                label="SMTP Port"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex justify-between gap-x-4 items-start">
                      <div className="w-full flex-shrink max-w-4xl">
                        <TextAccordion
                          headerTextPosition="left"
                          headerText="Advanced details">
                          <div className="w-full flex flex-col gap-y-3 justify-between  -ml-3">
                            <H4 caption="Enter in a testing email address and see if an email comes through.">Test you SMTP connection</H4>
                            <div className="flex gap-5 items-center w-full max-w-2xl  ml-3">
                              <div className="relative w-full">
                                <Input
                                  name="email"
                                  label="Email address"
                                />
                              </div>
                              <div className="mt-5">
                                <Button
                                  className="h-10 px-3"
                                  disabled={isEmailSendDisabled}
                                  onClick={sendTestEmail}
                                  type="button">
                                  Submit{isEmailSendLoading ? <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" /> : <></>}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </TextAccordion>
                      </div>
                      <div className="flex justify-between gap-x-4 flex-shrink-0 pt-2">
                        <Button
                          version="gray"
                          disabled={isDisabled}
                          onClick={onClear}>
                          Undo changes
                        </Button>
                        <Button
                          disabled={isDisabled}
                          type="submit">
                          Update{isLoading ? <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" /> : <></>}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Section>

      <SiteEmailManageModal
        data={templateData}
        isOpen={openTemplateModal}
        onCancel={() => setOpenTemplateModal(false)}
        onSuccess={() => {
          setOpenTemplateModal(false);
          refreshTemplateSite();
        }}
      />
    </div>
  );
};

export default SiteEmailManager;
