import { ArrowLongRightIcon, Bars3Icon, Cog6ToothIcon, PlusIcon, Square2StackIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { fetchData } from "src/async/fetch";
import DisplayPageCondition from "src/components/PageForm/DisplayPageCondition";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { apiUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import * as Yup from "yup";
import QuickMenu from "../Shared/QuickMenu";

const DisplayPageForm = ({
  pageOptions = [],
  operatorOptions = [],
  permission = {},
  setPermission = () => {},
  setDataChanges = () => {},
  saveDisplayName = () => {},
  dataChanges = 0,
  id,
  removePermission = () => {},
  trustedAttributeArr = [],
  valueType,
  draggableProps,
  dragHandleProps,
  innerRef,
  workspaceDetails,
  authorizeUserComponentAccessPermission = true,
  showNestedData = true,
  hideAliasManageButton = false,
  ...props
}) => {
  const [pageType, setPageType] = useState("");
  const [pageCopied, setPageCopied] = useState(false);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (pageCopied) {
      setTimeout(() => {
        setPageCopied(false);
      }, 6000);
    }
  }, [pageCopied]);

  const handleConditionAdd = () => {
    setPermission({
      ...permission,
      filters: [
        ...permission.filters,
        {
          column_name: "",
          operator_id: null,
          value_type: "value",
          column_value: "",
          trusted_attribute: "",
          addWhere: false,
          datasource_id: "",
        },
      ],
    });
    setDataChanges(dataChanges + 1);
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      setCustomFieldsLoading(true);

      try {
        const res = await fetchData("GET", `${apiUrl}/users/custom-fields`);
        const resData = await res.json();

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      } finally {
        setCustomFieldsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  useEffect(() => {
    let index = pageOptions.findIndex((op) => op._id === permission?.page_id);
    if (index !== -1) {
      setPageType(pageOptions[index].page_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions, permission?.page_id]);

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const selectedPage = pageOptions.find((page) => page._id === permission.page_id);

  useEffect(() => {
    if (selectedPage?.page_type) {
      let itemList = [];
      if (selectedPage?.page_type === "DOMO") {
        itemList.push({
          name: `Add Where`,
          onClick: () => {
            setPermission({
              ...permission,
              addWhere: true,
            });
            setDataChanges(dataChanges + 1);
          },
        });
      }
      itemList.push({
        name: "Add Alias",
        onClick: () => {
          setPermission({
            ...permission,
            displayNameShow: true,
          });
        },
      });
      setItems(itemList);
    } else {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanges, permission, selectedPage?.page_type]);

  return (
    <div
      className={"relative w-full max-w-9xl " + (props?.className ? props?.className : "")}
      ref={innerRef}
      {...draggableProps}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          page_id: permission?.page_id || "",
          datasource_id: permission?.datasource_id || "",
          page_alias: permission?.page_alias || "",
        }}
        validationSchema={Yup.object({
          page_id: Yup.string().required("Required"),
        })}>
        {({ values, errors, touched }) => {
          const handleOnChange = (e) => {
            const page = pageOptions.find((op) => op._id === e.target.value);
            if (page) {
              setPageType(page.page_type);
              setPermission({
                ...permission,
                filters: page.page_type === "CUSTOM_HTML" ? [] : permission?.filters,
                datasource_id: page.page_type === "CUSTOM_HTML" ? "" : permission?.datasource_id,
                addWhere: page.page_type === "CUSTOM_HTML" ? false : permission?.addWhere,
                [e.target.name]: e.target.value,
              });
              setDataChanges(dataChanges + 1);
            } else {
              if (e.target.name === "page_alias") {
                setPermission({
                  ...permission,
                  [e.target.name]: e.target.value,
                  displayNameChanges: false,
                });
              } else {
                setPermission({
                  ...permission,
                  [e.target.name]: e.target.value,
                });
                setDataChanges(dataChanges + 1);
              }
            }
          };
          return (
            <Form
              onChange={handleOnChange}
              className="relative w-full max-w-9xl bg-white rounded">
              <div className={`relative flex border border-gray-200 rounded-md w-full  ${pageType === "DOMO" ? "rounded-br-none" : ""}`}>
                {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                  <button
                    {...dragHandleProps}
                    type="button"
                    className="w-8 sm:min-w-[32px] flex items-center justify-center border-r border-gray-200 text-gray-700 outline-none ring-0 bg-gray-100 cursor-move">
                    <Bars3Icon className="w-5 h-5 stroke-2" />
                  </button>
                )}
                <div className="w-full flex items-center justify-between py-3 px-4">
                  <div className="flex">
                    <div className="flex pr-4 min-w-[200px]">
                      <Input
                        name="page_id"
                        as="select"
                        value={values.page_id}>
                        <option value="">Select</option>
                        {pageOptions.map((pageOption) => (
                          <option
                            key={pageOption._id}
                            value={pageOption._id}>
                            {pageOption.name}
                          </option>
                        ))}
                      </Input>
                    </div>
                    {permission?.displayNameShow && (
                      <>
                        <div className="flex pr-2 pl-8 min-w-[280px] flex-col relative">
                          <div className="absolute top-2 left-0">
                            <ArrowLongRightIcon className="w-5 h-5" />
                          </div>
                          <div className="relative">
                            <div className="absolute -top-2 left-2 text-xs text-gray-400 text font-semibold bg-white border-[1px] p-1 rounded-full border-gray-100 px-2 py-1 leading-none">Display Name</div>
                            <div className="group">
                              <Input
                                label=""
                                name="page_alias"
                                placeholder=""
                              />
                              {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                                <div className="absolute -top-2 -right-2 z-[1] opacity-0 invisible transition-all duration-150 group-hover:opacity-100 group-hover:visible">
                                  <button
                                    type="button"
                                    className={
                                      "group inline-flex items-center justify-center transition-all duration-200 px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor !p-0.5 !rounded-full hover:bg-gray-200 bg-white text-gray-400 border-gray-200 hover:border-gray-400 border-[1px] hover:shadow-sm hover:text-gray-00"
                                    }
                                    onClick={() => {
                                      setPermission({
                                        ...permission,
                                        page_alias: "",
                                        displayNameShow: false,
                                        displayNameChanges: true,
                                      });
                                      if (permission?._id) {
                                        saveDisplayName({ ...permission, ordering: props?.ordering, page_alias: "" });
                                      }
                                    }}>
                                    <XMarkIcon className="w-4 h-4 stroke-2 stroke-gray-500" />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {!props?.viewOnly && authorizeUserComponentAccessPermission && !hideAliasManageButton && (
                          <>
                            {!permission?.displayNameChanges && (
                              <div className="flex h-full items-center">
                                <button
                                  type="button"
                                  className={"group inline-flex items-center justify-center transition-all duration-200 rounded-md border px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor text-white bg-highlightColor opacity-90 hover:shadow-md"}
                                  onClick={() => {
                                    saveDisplayName({ ...permission, ordering: props?.ordering });
                                    setPermission({
                                      ...permission,
                                      displayNameChanges: true,
                                    });
                                  }}>
                                  Save
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex items-center">
                    {!props?.viewOnly && authorizeUserComponentAccessPermission && items.length > 0 && (
                      <QuickMenu
                        icon={
                          <div className="text-gray-400 hover:text-gray-500 h-full hover:bg-gray-50 py-2 px-2 rounded-md flex items-center justify-center">
                            <Cog6ToothIcon className="h-6 w-6" />
                          </div>
                        }
                        position="LEFT"
                        items={items}
                      />
                    )}
                    {workspaceDetails?.layout_type === "CUSTOM_NAVIGATION" && permission.page_id && (
                      <>
                        <button
                          data-tooltip-id={`${props?.id}-custom-link`}
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(`${props?.site?.site_domain}/workspaces/${props?.workspaceId}/page/${permission?.page_id}`);
                            setPageCopied(true);
                          }}
                          className="hidden sm:flex w-8 h-8 items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                          <div className="relative">
                            <Square2StackIcon className={classNames("w-6 h-6 transition-all duration-150", pageCopied ? "text-green-600/70" : "")} />
                            <div className={classNames("bg-white absolute -top-2 -right-2 rounded-full opacity-0 transition-all duration-150", pageCopied ? "opacity-100" : "")}>
                              <CheckCircleIcon className="w-5 h-5 text-green-600/50" />
                            </div>
                          </div>
                        </button>
                        <ReactTooltip
                          id={`${props?.id}-custom-link`}
                          delayShow={200}
                          positionStrategy="fixed"
                          className="opacity-100 bg-gray-700 rounded px-2 py-2">
                          <div className="font-normal leading-[10px]">Copy page link.</div>
                        </ReactTooltip>
                      </>
                    )}
                    {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                      <button
                        type="button"
                        onClick={removePermission}
                        className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {permission?.addWhere && (
                <div className="relative ml-[32px] border border-t-0 grid gap-y-2 border-gray-200 py-2 px-4 bg-white">
                  <div className="w-full flex items-center gap-4">
                    <div className="text-sm text-gray-800">Where</div>
                    <div className="relative w-80 py-2 group">
                      {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                        <div className="absolute -top-1 -right-2 z-[1] opacity-0 invisible transition-all duration-150 group-hover:opacity-100 group-hover:visible">
                          <button
                            type="button"
                            className={
                              "group inline-flex items-center justify-center transition-all duration-200 px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor bg-white text-gray-400 hover:border-gray-400 border-[1px] hover:shadow-sm hover:text-gray-00 !p-0.5 !rounded-full !border-gray-300 hover:bg-gray-200"
                            }
                            onClick={() => {
                              setDataChanges(dataChanges + 1);
                              setPermission({
                                ...permission,
                                datasource_id: "",
                                addWhere: false,
                              });
                            }}>
                            <XMarkIcon className="w-4 h-4 stroke-2 stroke-gray-500" />
                          </button>
                        </div>
                      )}
                      <Input
                        label=""
                        name="datasource_id"
                        placeholder="Datasource ID"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>

      {pageType === "DOMO" && (
        <>
          {(permission?.filters?.length > 0 || (!props?.viewOnly && authorizeUserComponentAccessPermission)) && (
            <div className="relative ml-[22px] sm:ml-[32px] border border-t-0 grid gap-y-2 border-gray-200 py-4 px-4 rounded-b-md bg-white border-b-rounded">
              {!customFieldsLoading &&
                permission.filters.map((condition, index) => (
                  <DisplayPageCondition
                    key={id + "_" + index}
                    condition={condition}
                    setCondition={(e) => {
                      setDataChanges(dataChanges + 1);
                      setPermission({
                        ...permission,
                        filters: permission.filters.map((p, i) => {
                          if (i === index) {
                            return e;
                          } else {
                            return p;
                          }
                        }),
                      });
                    }}
                    operatorOptions={operatorOptions}
                    removeCondition={(e) => {
                      setDataChanges(dataChanges + 1);
                      setPermission({
                        ...permission,
                        filters: permission.filters.filter((p, i) => i !== index),
                      });
                    }}
                    trustedAttributeArr={allTrustedAttributes}
                    valueType={valueType}
                    viewOnly={props?.viewOnly}
                    workspaceId={props?.workspaceId}
                    authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
                  />
                ))}
              {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                <div className="relative flex">
                  <button
                    onClick={handleConditionAdd}
                    type="button"
                    className="flex items-center justify-center h-10 py-0 px-0 text-base font-medium text-highlightColor gap-2">
                    <PlusIcon className="w-4 h-4 stroke-2" /> Add Condition
                  </button>
                </div>
              )}
              {/* Nested Pages */}
              {selectedPage?.nested_pages &&
                Array.isArray(selectedPage.nested_pages) &&
                selectedPage.nested_pages.map((page, i) => {
                  const pageItem = pageOptions.find((item) => item._id === page.page_id);
                  return (
                    <Fragment key={i}>
                      {!pageItem ? (
                        <></>
                      ) : (
                        <div className="flex items-center justify-between py-2 px-2 border border-gray-200 rounded-lg">
                          <select
                            className="w-[30%] h-10 block rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm"
                            disabled
                            value={pageItem.page_id}>
                            <option value={pageItem.page_id}>{pageItem.name}</option>
                          </select>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(DisplayPageForm);
