import { Menu, Transition } from "@headlessui/react";
import { CodeBracketIcon, WindowIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";

const AddPageDropdown = ({ addPage = () => {} }) => {
  return (
    <>
      <Menu
        as="div"
        className="relative z-50 inline-block text-left">
        <div>
          <Menu.Button className="h-full inline-flex items-center justify-center transition-all duration-200 rounded-md px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor  bg-white text-highlightColor border-[1px] border-highlightColor hover:shadow-sm sm:w-auto gap-x-4">
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: WindowIcon }}
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 relative z-50">
              <Menu.Item>
                <button
                  type="button"
                  onClick={() => {
                    addPage("DOMO");
                  }}
                  className={"relative z-50 bg-white group flex w-full items-center rounded-md px-3 py-2 gap-x-4 text-base"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                  </svg>
                  Domo Embed
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => {
                    addPage("CUSTOM_HTML");
                  }}
                  type="button"
                  className={"relative z-50 bg-white group flex w-full items-center rounded-md px-3 py-2 gap-x-4 text-base"}>
                  <CodeBracketIcon className="h-5 w-5" />
                  Custom HTML
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => {
                    addPage("EXTERNAL_LINK");
                  }}
                  type="button"
                  className={"relative z-50 bg-white group flex w-full items-center rounded-md px-3 py-2 gap-x-4 text-base"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>
                  External Link
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default AddPageDropdown;
