import { classNames } from "src/helpers/classNames";
import Input from "src/components/Shared/Forms/Inputs/Input";

const EditOnHoverInput = ({ size = "base", className = "text-gray-400", ...props }) => {
  const sizeGuide = {
    xs: "",
    sm: "",
    base: "text-regular font-medium",
    md: "",
    lg: "",
    xl: "text-3xl font-thin",
  };

  return (
    <button className="group relative">
      <Input
        {...props}
        inputClassNames={classNames("pr-4 focus:ring-0 focus:border-highlightColor bg-transparent hover:border-gray-400/20 border border-transparent flex items-center gap-x-2 text-regular px-2 py-1 rounded transition-all duration-75", sizeGuide[size], className)}
      />
    </button>
  );
};

export default EditOnHoverInput;
