import React, { useEffect, useState } from "react";
import Modal from "src/components/Shared/Modal";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { toast } from "react-toastify";

const SiteEmailManageModal = ({ data, isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const [disabled, setDisabled] = useState(false);

  const updateEmailTemplate = async () => {
    try {
      setDisabled(true);

      const res = await fetchData("PUT", `${apiUrl}/site-settings/email-template/${data._id}`, {
        subject,
        content,
      });
      const respData = await res.json();
      if (respData.status === 200) {
        onSuccess();
        setSubject("");
        setContent("");

        toast.success(respData.message);
      } else {
        toast.error(respData.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTimeout(() => setDisabled(false), 300);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSubject(data.subject);
      setContent(data.content);
    }
    return () => {
      setSubject("");
      setContent("");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.code, isOpen]);

  return (
    <Modal
      title="Email"
      secondaryTitle={`Edit`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={updateEmailTemplate}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-5">
        <div>
          <Input
            name="name"
            label="Subject"
            autoComplete="off"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <TextArea
          name="html"
          type="code"
          label="HTML Content"
          autoComplete="off"
          value={content}
          rows={10}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default SiteEmailManageModal;
