import React from "react";
import NestedPageContent from "./NestedPageContent";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";

const DomoPage = ({ newPage = {}, setNewPage = () => {}, startIndex = -1, pageCredentialOptions = {}, advancedFeatures = {}, setAdvancedFeatures = () => {}, nestedAllPages = [], reorderNestedPages = () => {}, pageTypes = {}, workspaceDetails = null, user = null }) => {
  const embedTypes = [
    { key: "Dashboard", value: "EMBED_PAGE" },
    { key: "Card", value: "EMBED_CARD" },
    { key: "Nested Pages", value: "NESTED_EMBED" },
  ];
  return (
    <div>
      <div className="grid gap-y-4">
        <div className="flex gap-x-3 w-full flex-col gap-y-3 sm:flex-row">
          <div className="flex-none w-1/3">
            {/* Embed Type */}
            <SelectMenu
              label="Embed Type"
              startIndex={embedTypes.findIndex((item) => item.value === newPage.embed_type)}
              options={embedTypes}
              setOption={(option) => {
                setNewPage({
                  ...newPage,
                  embed_type: option.value,
                });
              }}
            />
          </div>
          <div className="flex-grow">
            {/* Credentials */}
            <SelectMenu
              label="DOMO credentials"
              startIndex={startIndex}
              options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
              setOption={(option) => {
                setNewPage({ ...newPage, credential_id: option.value });
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <FrontLabeledInput
            label="Embed ID"
            type="text"
            value={newPage.embed_id}
            onChange={(e) => setNewPage({ ...newPage, embed_id: e.target.value })}
          />
        </div>
      </div>

      {newPage.is_global && user?.type === "admin" && (
        <TextAccordion headerText="Advanced features">
          <div className="-mx-2 flex flex-wrap">
            {/* <div className="mb-4 flex w-full px-2">
                <input
                  id="card-embed"
                  checked={newPage.embed_type === "EMBED_CARD" ? true : false}
                  onChange={(e) => {
                    setNewPage({
                      ...newPage,
                      embed_type: e.target.checked ? "EMBED_CARD" : "EMBED_PAGE",
                    });
                  }}
                  aria-describedby="card-embed"
                  name="card-embed"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                />
                <div className="ml-3 max-w-[400px]">
                  <label
                    htmlFor="card-embed"
                    className="text-sm text-gray-500">
                    Display dashboard as a Domo "Card"
                  </label>
                  <p className="whitespace-normal text-sm text-gray-400">*Individual cards must have card embed_ids using Domo Everywhere.</p>
                </div>
              </div> */}

            <div className="mb-4 flex w-full px-2">
              <input
                id="ignore-global-filters"
                checked={newPage.ignore_global_filters}
                onChange={(e) => {
                  setNewPage({
                    ...newPage,
                    ignore_global_filters: e.target.checked,
                  });
                }}
                aria-describedby="ignore-global-filters"
                name="ignore-global-filters"
                type="checkbox"
                className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
              />
              <div className="ml-3 max-w-[400px]">
                <label
                  htmlFor="ignore-global-filters"
                  className="text-sm text-gray-500">
                  Ignore user-specific and workspace-specific <b>Data Access</b> controls
                </label>
                <p className="whitespace-normal text-sm text-gray-400">If enabled, both user-specific and global workspace data access controls will be ignored when viewing this page.</p>
              </div>
              {/* <div className="ml-3 max-w-[400px]">
                  <label
                    htmlFor="ignore-global-filters"
                    className="text-sm text-gray-500">
                    Ignore user-specific Data Access controls
                  </label>
                  <p className="whitespace-normal text-sm text-gray-400">If enabled, user-specific data access controls will be ignored when viewing this page.</p>
                </div>
                <div className="ml-3 max-w-[400px]">
                  <label
                    htmlFor="ignore-global-filters"
                    className="text-sm text-gray-500">
                    Ignore workspace-specific Data Access controls
                  </label>
                  <p className="whitespace-normal text-sm text-gray-400">If enabled, workspace-specific data access controls will be ignored when viewing this page.</p>
                </div> */}
            </div>
          </div>
        </TextAccordion>
      )}
      {newPage.embed_type === "NESTED_EMBED" && <hr className="my-6" />}
      {newPage.embed_type === "NESTED_EMBED" && (
        <NestedPageContent
          newPage={{
            ...newPage,
            nested_pages: !workspaceDetails
              ? newPage.nested_pages.filter((item) => (item.page_id !== null ? nestedAllPages.map((page) => page._id).includes(item.page_id) : item !== null))
              : newPage.nested_pages.filter((item) => (item.page_id !== null ? (workspaceDetails.pages || []).map((page) => page.page_id).includes(item.page_id) : item !== null)),
          }}
          setNewPage={setNewPage}
          pages={nestedAllPages}
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            reorderNestedPages(newPage.nested_pages, result.source.index, result.destination.index);
          }}
          onAddPage={() =>
            setNewPage((page) => ({
              ...page,
              nested_pages: [...page.nested_pages, { page_id: null, ordering: page.nested_pages.length }],
            }))
          }
          onUpdatePage={(data, index) => {
            setNewPage((page) => ({
              ...page,
              nested_pages: page.nested_pages.map((page, i) =>
                i !== index
                  ? page
                  : {
                      page_id: data.page_id,
                      ordering: i,
                    }
              ),
            }));
          }}
          onRemovePage={(index) =>
            setNewPage((page) => ({
              ...page,
              nested_pages: page.nested_pages.filter((page, i) => i !== index),
            }))
          }
        />
      )}
    </div>
  );
};

export default DomoPage;
