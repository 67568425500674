import React from "react";
import { classNames } from "src/helpers/classNames";

const TextArea = ({ autoComplete = "on", rows = 3, label, name, disabled = false, value = "", error = false, type = "text", inputClassNames, labelClassNames = "", onChange = () => {}, onFocus = () => {}, onKeyDown = () => {}, placeholder = "", textType = "" }) => {
  const labelClasses = classNames(labelClassNames, "flex justify-between text-sm font-medium", error ? "text-red-600" : "", disabled ? "text-gray-400 cursor-not-allowed" : "text-gray-700");
  const inputClasses = classNames("min-h-[80px] block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor", error ? "border-red-300" : "", disabled ? "text-gray-400 cursor-not-allowed bg-gray-50/50" : "");

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={labelClasses}>
        {label} <div className="pl-2">{error ? "*Required" : ""}</div>
      </label>
      <textarea
        disabled={disabled}
        className={classNames(inputClassNames ? inputClassNames : inputClasses, type === "code" ? "font-mono" : "")}
        placeholder={placeholder}
        rows={rows}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
