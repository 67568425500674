import { useEffect, useState, useCallback } from "react";
import { EnvelopeIcon, TrashIcon, UserMinusIcon, UsersIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { addUser, deleteUser, getGroupData, manageUserRole } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import DataExport from "src/components/Shared/DataExport";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import RadioGroup from "src/components/Shared/Forms/Inputs/RadioGroup";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import MultiSelectButton from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectButton";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import UserEntry from "src/components/Users/List/UserEntry";
import MultiSelectObjectUsers from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObjectUsers";
import { apiUrl } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import { getUrlParams } from "src/helpers/getUrlParams";
import { urlBuilder } from "src/helpers/urlBuilder";
import useFetch from "src/hooks/useFetch";

const WorkspaceUsersList = ({ user = {}, ...props }) => {
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];
  const [deleteId, setDeleteId] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [addExistingUser, setAddExistingUser] = useState(false);
  const defaultState = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    image: "",
    type: "participant",
    notifications: false,
    update: true,
  };
  const [state, setState] = useState(defaultState);
  // Search
  const [keyword, setKeyword] = useState("");
  const [status] = useState("");
  const [group] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  // Pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  // All User
  const [userLoaded, setUserLoaded] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedExistingUserGroups, setSelectedExistingUserGroups] = useState([]);
  const [successModalStart, setSuccessModalStart] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [domoUser, setDomoUser] = useState({});
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [disableActions, setDisableActions] = useState(false);
  const [isDeleteMultiUserModalOpen, setIsDeleteMultiUserModal] = useState(false);
  const [initialCustomFields, setInitialCustomFields] = useState([])
  const [customFields, setCustomFields] = useState([]);

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const res = await fetchData("GET", `${apiUrl}/users/custom-fields`);
        const resData = await res.json();

        if (resData.status === 200 && resData.data) {
          setInitialCustomFields(resData.data.map(item => ({
            ...item,
            value: item.default_value || ''
          })))
          setCustomFields(resData.data.map(item => ({
            ...item,
            value: item.default_value || ''
          })));
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => { 
    loadCustomFields() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let {
    params: { workspaces: workspaceId },
  } = getUrlParams(window);

  const {
    response: { data: users, meta },
    status: { done: usersLoaded },
    refreshData: refreshUsers,
  } = useFetch(workspaceId && props.workspaceDetails?._id ? `/workspaces/:workspace_id/users` : "", { params: { workspace_id: workspaceId }, query: { limit: limit, offset: offset, keyword: keyword, status: status, group: group } });

  const {
    response: { data: domoUsers },
  } = useFetch(workspaceId && props.workspaceDetails?._id && props.workspaceDetails?.workspace_type === 'JWT_FULL_EMBED' ? `/workspaces/:workspace_id/domo-users` : "", { params: { workspace_id: workspaceId } });

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, status, group]);

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  // Load groups
  useEffect(() => {
    // const ac = new AbortController();

    const loadGroups = async (workspaceId) => {
      try {
        if (props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
          const res = await apiRequest("get", `/workspaces/:workspace_id/domo-groups`, {
            params: { workspace_id: workspaceId },
            queries: { auth_token: props.workspaceDetails?.auth_token },
          });

          if (res.data.status === 200) {
            setGroupList(res.data.data);
          }
        } else {
          await props.getGroupData({});
        }
      } catch (error) {
        // console.dir("ERROR:", error.message);
      }
    };

    if (workspaceId) {
      loadGroups(workspaceId);
    }

    // return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.workspaceDetails?.auth_token, props.workspaceDetails?.workspace_type]);

  useEffect(() => {
    if (props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      setGroupList(props.groups.filter((grp) => grp?.workspace_id?._id === props.workspaceDetails?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.groups, props.workspaceDetails]);

  useEffect(() => {
    const loadUsers = async () => {
      const res = await apiRequest("get", "users/list", { queries: { type: "user/editor" } });

      if (res.status === 200) {
        setAllUsers(res.data.data);
      }

      setUserLoaded(true);
    };

    loadUsers();
  }, []);

  const removeUser = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setSuccessModalStart(true);
      const res = await fetchData("POST", `${apiUrl}/workspaces/${workspaceId}/users/remove`, {
        users: [deleteId],
        auth_token: props.workspaceDetails?.auth_token,
      });
      const { status, message } = await res.json();
      if (status === 200) {
        setDeleteId(null);
        toast.success(message);
        setSuccessModalStart(false);
        if(users.length === 1 && offset !== 0){
          setOffset(offset - 1);
         }else{
           refreshUsers();
         }
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    }
  };

  const removeUserAccess = async (userId = "") => {
    try {
      const res = await apiRequest("DELETE", `/workspaces/${props.workspaceDetails?._id}/users/${userId}/permissions`);

      if (res.data.status === 200) {
        if(users.length === 1 && offset !== 0){
          setOffset(offset - 1);
         }else{
           refreshUsers();
         }
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const prepData = async () => {
    const params = { keyword: keyword, workspace_id: workspaceId };
    try {
      const res = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/users`, params);
      const { data } = await res.json();

      let exportDoc = [];
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let groupName = [];
          if (element?.groups && element.groups.length > 0) {
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name);
            }
          }

          exportDoc.push({
            Name: element?.name,
            Email: element?.email,
            Type: element?.type,
            Groups: groupName.length > 0 ? groupName?.toString() : "",
            "Date Created": moment(new Date(element?.created_at)).format("MM/DD/YYYY HH:mm:ss"),
            "Last Login": element?.last_login ? moment(new Date(element?.last_login)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
            "Is Active": element?.active_status ? "true" : "false",
            "Deactivation Dated": !element?.active_status ? moment(new Date(element.deactivated_at)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
          });
        }
      }
      return exportDoc;
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    let updatedGroups = groupList.filter((group) => selectedGroups.includes(group._id));
    setState({
      ...state,
      groups: updatedGroups,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const handleSelectedGroups = (groups) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  const onSubmit = async (users = null) => {
    try {
      if (
        typeof users !== "boolean" && 
        !Array.isArray(users) &&
        props.workspaceDetails?.workspace_type === 'JWT_FULL_EMBED'
      ) {
        if (state.name && state.email) {
          const existingUser = allUsers.find((user) => user.email.toLowerCase() === state.email.toLowerCase());

          if (existingUser) {
            toast.error("This email is taken.");
            return;
          }

          const findDomoUser = domoUsers.find((user) => user.email.toLowerCase() === state.email.toLowerCase());

          if (findDomoUser) {

            setAddUser(false);
            setAddExistingUser(false);
            setDomoUser(findDomoUser);

            setIsImportModalOpen(true);
            return;
          }
        }
      }

      setSuccessModalStart(true);
      const res = await fetchData("POST", `${apiUrl}/workspaces/${workspaceId}/users/add`, {
        users: Array.isArray(users) ? users.map((user) => ({ ...user, groups: selectedGroups, custom_fields: customFields })) : [{ ...state, groups: selectedGroups, custom_fields: customFields }],
        auth_token: props.workspaceDetails?.auth_token,
        existing: Array.isArray(users) ? true : false,
        isDomo: props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED",
        workspace_id: props.workspaceDetails?._id,
      });
      const { status, message } = await res.json();
      if (status === 200) {
        toast.success(message);
        refreshUsers();
        setAddUser(false);
        setAddExistingUser(false);
        setSelectedUsers([]);
        setSelectedExistingUserGroups([]);

        setState(defaultState);

        setIsImportModalOpen(false);
        setDomoUser(false);
      } else {
        toast.error(message);
      }
      setSuccessModalStart(false);
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    } finally {
    }
  };

  const manageUserRole = async (e, user) => {
    try {
      const data = await props.manageUserRole({
        id: user._id,
        type: e.value,
        auth_token: props.workspaceDetails?.auth_token,
        workspace_id: workspaceId,
      });
      toast.success(data);
    } catch (error) {
      refreshUsers();
      toast.error(error.message);
    }
  };

  const onUserModalClose = () => {
    setIsImportModalOpen(false);
    setDomoUser({});

    setAddUser(false);
    setAddExistingUser(false);
    setSelectedUsers([]);
    setSelectedExistingUserGroups([]);

    setState(defaultState);
    setCustomFields(initialCustomFields)
  };

  const inviteMultiUser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/send-reset-email", { body: { userIds: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const deactivateMultiUser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/manage-status", { body: { id: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        refreshUsers();

        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const deleteMultiuser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", urlBuilder(`/workspaces/:workspace_id/users/remove`, { workspace_id: props.workspaceDetails?._id }), { body: { users: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        setIsDeleteMultiUserModal(false);
        refreshUsers();

        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error("Something went wrong.");
    } finally {
      setDisableActions(false);
    }
  };

  const updateCustomFields = (index, value) => {
    setCustomFields(fields => fields.map((field, i) => i !== index ? field : {
      ...field,
      value
    }))
  }

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
          />
        }
        middleContent={
          <>
            {user?.type === "admin" && (
              <div className={classNames("py-1 bg-gray-50/80 rounded-full px-4 ml-3 flex items-center gap-x-1 transition-all duration-200", checkedUsers?.length > 0 ? "max-w-[400px] opacity-100" : "cursor-not-allowed opacity-40 overflow-x-hidden")}>
                <MultiSelectButton
                  name="Resend welcome invites"
                  disabled={checkedUsers?.length === 0}
                  onClick={
                    // () => {}
                    inviteMultiUser
                  }
                  Icon={EnvelopeIcon}
                />
                <span className="text-gray-300 px-2">|</span>
                <MultiSelectButton
                  name="Deactivate users"
                  disabled={checkedUsers?.length === 0}
                  onClick={deactivateMultiUser}
                  Icon={UserMinusIcon}
                />
                {props?.site?.api_state !== "DOMO_DATASET" && (
                  <MultiSelectButton
                    name="Delete users"
                    disabled={checkedUsers?.length === 0}
                    onClick={() => setIsDeleteMultiUserModal(true)}
                    Icon={TrashIcon}
                  />
                )}
              </div>
            )}
          </>
        }
        rightContent={
          authorizeUserComponentAccess(user, workspaceId, "user", ["create"]) && (
            <Button
              version="secondary"
              onClick={() => setAddUser(true)}>
              <IconsWithPlus
                strokeColor={"stroke-highlightColor"}
                item={{ icon: UsersIcon }}
              />
            </Button>
          )
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            {
              user?.type === "admin" &&
              <th
                scope="col"
                className="whitespace-nowrap py-3 pl-1 text-sm sm:pl-4">
                <Checkbox
                  isChecked={checkedUsers.length && checkedUsers.length === users.length}
                  onChange={() => {
                    if (checkedUsers.length === users.length) {
                      setCheckedUsers([]);
                    } else {
                      setCheckedUsers(users.map((user) => user._id));
                    }
                  }}
                />
              </th>
            }
            <th
              scope="col"
              className="rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
              <th
                scope="col"
                className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Domo Role
              </th>
            )}
            <th
              scope="col"
              className="rounded-tr-lg relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={usersLoaded}
        dataExists={users?.length > 0}>
        {users?.length > 0 &&
          users.reduce((filteredUsers, user) => {
            return filteredUsers.length < limit
              ? [
                  ...filteredUsers,
                  <UserEntry
                    key={user._id}
                    user={user}
                    deleteId={deleteId}
                    setDeleteId={setDeleteId}
                    refreshUsers={refreshUsers}
                    manageUserRole={manageUserRole}
                    workspaceDetails={props.workspaceDetails}
                    removeUserAccess={removeUserAccess}
                    allowMulti
                    checkedUsers={checkedUsers}
                    setCheckedUsers={setCheckedUsers}
                    disableCheckButton={disableActions}
                  />,
                ]
              : filteredUsers;
          }, [])}
      </Table>
      <PaginationFooter
        itemName="User record"
        count={users?.length ? meta?.count : 0}
        loaded={usersLoaded}
        limit={limit}
        offset={offset}
        tabCount={usersLoaded && Math.ceil(meta?.count / limit)}
        onChange={handlePaginationChange}
      />
      {user.type !== "user" && (
        <DataExport
          title="user_list_report"
          headings={["Name", "Email", "Type", "Groups", "Date Created", "Last Login", "Is Active", "Deactivated Date"]}
          prepData={prepData}
        />
      )}

      <Modal
        title="User"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeUser}
        defaultOptions={{
          onSuccessButtonText: "Delete",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {usersLoaded && users?.find((user) => user._id === deleteId)?.name}?</div>
      </Modal>

      {user?.type === "admin" && (
        <Modal
          title="User(s)"
          secondaryTitle="Delete"
          isOpen={isDeleteMultiUserModalOpen}
          onCancel={() => setIsDeleteMultiUserModal(false)}
          onSuccess={deleteMultiuser}
          isLoading={disableActions}
          defaultOptions={{
            onSuccessButtonText: "Delete",
          }}>
          <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete the selected User(s)?</div>
        </Modal>
      )}

      <Modal
        title={props.workspaceDetails?.name}
        secondaryTitle="Create User"
        isOpen={addUser}
        onCancel={onUserModalClose}
        onSuccess={onSubmit}
        defaultOptions={{
          onSuccessButtonText: "Submit",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}
      >
        <div className="relative w-full flex flex-col md:flex-row gap-4">
          <div className="relative w-full md:w-1/2">
            <Input
              name="fname"
              label="Full Name"
              type={"text"}
              value={state.name}
              autoComplete={"new-fname"}
              onChange={(e) =>
                setState({
                  ...state,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="relative w-full md:w-1/2">
            <Input
              name="email"
              label="Email"
              type={"email"}
              value={state.email}
              autoComplete={"new-email"}
              onChange={(e) =>
                setState({
                  ...state,
                  email: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="relative w-full flex flex-col md:flex-row gap-4">
          {
            customFields.length > 0 &&
            customFields.map((field, i) => (
              <div className="relative w-full md:w-1/2" key={field._id}>
                <Input
                  name={field.label}
                  placeholder={field.placeholder || field.label}
                  label={field.label}
                  value={field.value || ""}
                  onChange={(e) => updateCustomFields(i, e.target.value)}
                />
              </div>
            ))
          }
        </div>
        {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
          <div className="relative w-full md:w-1/2 mt-4">
            <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Domo Role</label>
            <SelectMenu
              options={roles}
              setOption={(e) =>
                setState({
                  ...state,
                  type: e.value,
                })
              }
              startIndex={roles.findIndex((rl) => rl.value === state.role) > 0 ? roles.findIndex((rl) => rl.value === state.role) : 0}
            />
          </div>
        )}
        <div className="relative w-full mt-4">
          <MultiSelectObject
            defaultOptions={groupList?.map((group) => {
              return { key: group.id || group._id, value: group.name, selected: false, object: group };
            })}
            searchableFields={["name"]}
            title="Assign groups"
            onChange={handleSelectedGroups}
          />
        </div>

        <TextAccordion
          headerText="Advanced features"
          headerTextPosition="left">
          <div className="w-full flex flex-col">
            <RadioGroup
              title="Send welcome email on setup"
              subtitle="Disabling this will allow you to manually enter a password"
              options={[
                { id: "send-email", title: "Send invite email" },
                { id: "manual", title: "Manually set password" },
              ]}
              defaultChecked={state.update ? "send-email" : "manual"}
              onChange={(id) => {
                setState({
                  ...state,
                  update: id === "send-email",
                });
              }}
              layout="horizontal"
            />
            <div className={classNames("grid gap-y-3 transition-all duration-150", state.update ? "mt-2 max-h-0 overflow-hidden opacity-0" : "mt-6 opacity-100 max-h-[200px]")}>
              <p className="p-0 text-gray-300 text-sm">
                Please enter a <span className="text-gray-400 font-semibold px-[2px]">temporary</span> password that should be reset upon logging in.
              </p>
              <div className="w-full flex gap-y-2 gap-x-4">
                {/* Password */}
                <div className="w-full">
                  <Input
                    name="fname"
                    label="Password"
                    type={"password"}
                    value={state.password}
                    autoComplete={"new-password"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        password: e.target.value,
                      })
                    }
                  />
                </div>

                {/* Confirm Password */}
                <div className="w-full">
                  <Input
                    name="fname"
                    label="Confirm Password"
                    type={"password"}
                    value={state.confirm_password}
                    autoComplete={"new-password"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {/* <ToggleHeader
              title="Manually enter a password"
              subtitle='Enabling this feature will disable the default "welcome email".'
              position="left">
              <Toggle
                checked={!state.update}
                onChange={(e) => {
                  setState({
                    ...state,
                    update: !e,
                  });
                }}
              />
            </ToggleHeader>
            {!state.update && (
              <div className="relative w-full flex md:flex-col mb-4 gap-x-3 gap-y-3">
                <div className="relative w-full md:max-w-lg">
                  <Input
                    name="fname"
                    label="Password"
                    type={"password"}
                    value={state.password}
                    autoComplete={"new-password"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="relative w-full md:max-w-lg">
                  <Input
                    name="fname"
                    label="Confirm Password"
                    type={"password"}
                    value={state.confirm_password}
                    autoComplete={"new-password"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )} */}
          </div>
        </TextAccordion>

        {user?.type === "admin" && (
          <>
            <hr className="w-full mt-8" />
            <div className="relative w-full flex mt-4">
              <div
                className="transition-all duration-200 border border-transparent py-1 px-2 rounded hover:border-highlightColor group flex gap-x-4 items-center text-gray-600 hover:cursor-pointer hover:text-highlightColor"
                onClick={() => {
                  setAddExistingUser(true);
                  setAddUser(false);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-gray-600 group-hover:stroke-highlightColor"}
                  item={{ icon: UsersIcon }}
                />
                Add an existing user
              </div>
            </div>
          </>
        )}
      </Modal>
      {/* User import modal */}
      <Modal
        title={props.workspaceDetails?.name}
        secondaryTitle="Import User"
        isOpen={isImportModalOpen}
        onCancel={onUserModalClose}
        onSuccess={() => onSubmit(true)}
        defaultOptions={{
          onSuccessButtonText: "Yes",
          onCancelButtonText: "No",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <h1>An user with this email already exists on DOMO, do you still want to import on our Portal?</h1>
        <h3 className="font-bold mt-5 ml-5">{domoUser?.name || ""}</h3>
        <h5 className="mt-1 ml-5 font-semibold">{domoUser?.email || ""}</h5>
      </Modal>
      <Modal
        title={props.workspaceDetails?.name}
        secondaryTitle={`Add an existing user`}
        isOpen={addExistingUser}
        onCancel={() => setAddExistingUser(false)}
        onSuccess={() => {
          onSubmit(allUsers.filter((user) => selectedUsers.includes(user._id)).map((user) => ({ ...user, groups: selectedExistingUserGroups })));
        }}
        defaultOptions={{
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}
        defaultStyles={{
          overFlowYVisible: true,
        }}>
        <MultiSelectObjectUsers
          usersLoaded={userLoaded}
          users={allUsers?.filter((singleUser) => !users?.map((user) => user._id).includes(singleUser._id))}
          selectedUserIds={selectedUsers}
          handleSelectedUsers={(allSelectedUsers) => {
            setSelectedUsers(allSelectedUsers.filter((user) => user.selected).map((user) => user.object._id));
          }}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, {
  deleteUser,
  getGroupData,
  addUser,
  manageUserRole,
})(WorkspaceUsersList);
