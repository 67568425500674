import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { classNames } from "src/helpers/classNames";

const MultiRowSelectButton = ({ disabled = true, name = "", component = null, Icon = null, onClick = () => {}, site = {} }) => {
  return (
    <div>
      {!disabled && (
        <ReactTooltip
          id={`${name}-multi_select_button_option`}
          delayShow={100}
          positionStrategy="fixed"
          className="z-[200] opacity-100 bg-gray-500 rounded px-2 py-2">
          {name}
        </ReactTooltip>
      )}
      <button
        data-tooltip-id={`${name}-multi_select_button_option`}
        disabled={disabled}
        className={classNames("group flex transition-all duration-200 justify-center items-center p-1.5 rounded-full", disabled ? "cursor-not-allowed" : "hover:bg-gray-100")}
        onClick={onClick}>
        {Icon ? <Icon className={classNames("transition-all duration-200 group text-gray-400 h-6 w-6 ", disabled ? "" : site?.highlight_color ? "group-hover:text-highlightColor" : "group-hover:text-gray-100")} /> : component}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};


export default connect(mapStateToProps, {})(MultiRowSelectButton);