import { ArrowTopRightOnSquareIcon, ClipboardDocumentListIcon, UserIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import { noFavicon, domoGrayscaleJWT } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import Image from "../Shared/Image";

const WorkspaceEntry = ({ workspace }) => {
  const [pages, setPages] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (workspace?._id && workspace?.workspace_type === "JWT_FULL_EMBED") {
      const loadPages = async () => {
        const res = await apiRequest("get", `/workspaces/${workspace?._id}/domo-pages`);
        if (res.status === 200) {
          setPages(res.data.data || []);
        }
      };

      const loadUserGroups = async () => {
        const res = await apiRequest("post", `/workspaces/${workspace?._id}/user-groups`, {
          body: {
            workspace_id: workspace?._id,
          },
        });
        if (res.status === 200) {
          setGroups(res.data.data?.groups || []);
        }
      };

      loadPages();
      loadUserGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?._id]);

  return (
    <TableRow keyId={workspace?._id}>
      <TRBody className="whitespace-nowrap">
        <div className="w-full flex gap-x-3 items-center relative">
          <Link
            to={`/workspaces/${workspace._id}`}
            className="border border-transparent pr-5 rounded-md text-gray-900 transition-all duration-200 font-medium cursor-pointer group">
            <div className="flex gap-4 items-center">
              <div
                style={!workspace?.square_logo && workspace.image_logo ? { background: workspace.top_bar_color } : {}}
                className="w-14 h-14 overflow-hidden rounded bg-white/10">
                <Image
                  image={workspace.square_logo || workspace.image_logo || workspace.image_favicon}
                  failImage={noFavicon}
                  alt={"Workspace"}
                />
              </div>
              <div>
                <div className="w-[100px] group-hover:underline sm:w-auto truncate sm:overflow-visible text-gray-700 group-hover:text-gray-900 sm:max-w-auto h-full flex items-center gap-x-2">
                  {workspace?.name} <ArrowTopRightOnSquareIcon className="transition-all duration-150 h-4 w-4 opacity-20 group-hover:opacity-70" />
                </div>
                {workspace?.workspace_type === "JWT_FULL_EMBED" && (
                  <div className="flex gap-x-2 items-center justify-center text-sm text-gray-400 bg-gray-50/80 rounded py-1 px-2 -ml-1">
                    <div className="h-4 w-4 opacity-90">
                      <Image image={domoGrayscaleJWT} />
                    </div>
                    JWT - Embeded App
                  </div>
                )}
              </div>
            </div>
          </Link>
        </div>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-1 pr-1 sm:pr-3 text-sm sm:pl-6">
        <Link to={`/workspaces/${workspace?._id}?tab=Pages`}>
          <div className={classNames("font-medium w-12 flex gap-x-1 hover:underline cursor-pointer transition-all duration-100", (workspace?.workspace_type === "JWT_FULL_EMBED" ? pages?.length > 0 : workspace?.pages?.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <WindowIcon className="h-5 w-5" />
            {workspace?.workspace_type !== "JWT_FULL_EMBED" ? workspace.pages?.length || 0 : workspace.workspace_type && workspace?.workspace_type === "JWT_FULL_EMBED" ? pages.length || 0 : 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-1 pr-1 sm:pr-3 text-sm sm:pl-6">
        <Link to={`/workspaces/${workspace?._id}?tab=Groups`}>
          <div className={classNames("font-medium w-12 flex gap-x-1 hover:underline cursor-pointer transition-all duration-100", (workspace?.workspace_type === "JWT_FULL_EMBED" ? groups?.length > 0 : workspace?.groups?.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <ClipboardDocumentListIcon className="h-5 w-5" />
            {workspace?.workspace_type !== "JWT_FULL_EMBED" ? workspace.groups?.length || 0 : workspace.workspace_type && workspace?.workspace_type === "JWT_FULL_EMBED" ? groups.length || 0 : 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-1 pr-1 sm:pr-3 text-sm sm:pl-6">
        <Link to={`/workspaces/${workspace?._id}?tab=Users`}>
          <div className={classNames("font-medium w-12 flex gap-x-1 hover:underline cursor-pointer transition-all duration-100", workspace?.userIds?.length > 0 ? "text-gray-900" : "text-gray-300")}>
            <UserIcon className="h-5 w-5" />
            {workspace?.userIds?.length || 0}
          </div>
        </Link>
      </TRBody>
      {/* <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/workspaces/${workspace._id}`);
                },
              },
            ]}
          />
        </div>
      </td> */}
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceEntry);
