import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { replacePrivateStringWithStars } from "src/helpers/replacePrivateStringWithStars";

import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import { TrashIcon } from "@heroicons/react/24/outline";

const WorkspaceIntegrationClientsItem = ({ user, workspaceId, state, setState = () => {}, removePageCredential, workspaceType = "" }) => {
  const [viewOnly, setViewOnly] = useState(true);
  const [integrationStatus, setIntegrationStatus] = useState("INCOMPLETE_DATA");
  const [existingData, setExistingData] = useState(state?.name && state?.client_id_count > 0 && state?.client_secret_count > 0);

  useEffect(() => {
    let authResponse = authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]);
    setViewOnly(!authResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e, name) => {
    let existingValues = {};
    if (e.target.value) {
      existingValues = { name: state?.name, client_id: state?.client_id, client_secret: state?.client_secret };
      existingValues[name] = e.target.value;
    }
    if (existingValues.name && existingValues.client_id && existingValues.client_secret) {
      setIntegrationStatus("COMPLETED_DATA");
    } else {
      setExistingData(false);
      setIntegrationStatus("INCOMPLETE_DATA");
    }
  };

  return (
    <div className="w-full h-full relative border border-gray-50 shadow pb-3 pt-.5 px-4 rounded-md">
      {!existingData && (
        <div className={classNames("absolute flex top-1 right-1 items-center gap-x-1 px-1 py-[2px] rounded transition-colors duration-100", integrationStatus === "INCOMPLETE_DATA" ? "bg-red-50 text-red-300" : "bg-green-50 text-green-300 border-[1px] border-green-200")}>
          {integrationStatus === "INCOMPLETE_DATA" ? (
            <>
              <XMarkIcon className="h-5 w-5" />
              Incomplete Credential
            </>
          ) : (
            <>
              <CheckIcon className="h-5 w-5" />
              Done
            </>
          )}
        </div>
      )}
      <div className="w-full h-full flex gap-x-2">
        <div className="w-full flex flex-col xl:flex-row gap-2 xl:items-end">
          <div className="w-full sm:w-1/2 pr-1 xl:pr-0 xl:w-[300px]">
            <Input
              label="Name"
              name="name"
              disabled={viewOnly}
              value={state.name || ""}
              onChange={(e) => {
                handleInputChange(e, "name");
                setState({ ...state, name: e.target.value });
              }}
            />
          </div>
          <div className="xl:w-[calc(100%-300px)] flex xl:mt-1 xl:pt-5 flex-col sm:flex-row gap-2">
            <FrontLabeledInput
              label="Client ID"
              type="password"
              placeholder={replacePrivateStringWithStars(state.client_id_count)}
              value={state.client_id || ""}
              disabled={viewOnly}
              onChange={(e) => {
                handleInputChange(e, "client_id");
                setState({ ...state, client_id: e.target.value });
              }}
            />
            <FrontLabeledInput
              label="Client Secret"
              autoComplete={"new-password"}
              type="password"
              placeholder={replacePrivateStringWithStars(state.client_secret_count)}
              value={state.client_secret || ""}
              disabled={viewOnly}
              onChange={(e) => {
                handleInputChange(e, "client_secret");
                setState({ ...state, client_secret: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="xl:mt-1 pt-5">
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["delete"]) && (
            <div className="xl:h-full flex xl:items-center">
              <button
                type="button"
                className="p-1 hover:bg-gray-100/70 transition-all duration-300 rounded-md text-gray-400 hover:text-gray-500 border-transparent focus:outline-none border-[2px] hover:border-gray-200"
                onClick={() => removePageCredential(state._id)}>
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceIntegrationClientsItem;
