import { useState, useCallback, useEffect } from "react"
import PageContainer from "src/components/Pages/PageContainer"
import { PageListGlobalContext } from "src/context/PageListContext";
import { apiRequest } from "src/async/apiUtils";
import { connect } from "react-redux";
import { managePageData, removePage, setPageData } from "src/actions/page";

const PageListGlobal = ({ workspaceId = "", ...props }) => {
  const [keyword, setKeyword] = useState("");
  const [meta, setMeta] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [pagesLoaded, setPagesLoaded] = useState(false);
  const [pageType, setPageType] = useState(null);

  const [pages, setPages] = useState([])

  const resetPagination = () => {
    setOffset(0)
    setMeta(null)
  }

  const workspacePages = useCallback(async () => {
    const res = await apiRequest("get", `/workspaces/${workspaceId}/domo-pages`, {
      queries: { sort: "ASC", limit, offset, keyword, authToken: props?.workspaceDetails?.auth_token },
    });

    if (res.data.status === 200) {
      setPages(res.data.data || []);
      setPagesLoaded(true);
      setMeta(res.data.meta);
    } else {
      setPagesLoaded(true);
    }
  }, [workspaceId, limit, offset, keyword, props?.workspaceDetails?.auth_token]);

  const menuPageList = useCallback(async () => {
    const res = await apiRequest("post", `/pages/list`, {
      body: { limit, offset, keyword, isGlobal: true, workspace_id: workspaceId, workspace_type: "IFRAME_EMBED" },
    });

    if (res.data.status === 200) {
      setPages(res.data.data || []);
      setPagesLoaded(true);
      setMeta(res.data.meta);
    }
  }, [limit, offset, keyword, workspaceId]);

  useEffect(() => {
    if (!workspaceId) {
      setPageType("IFRAME_EMBED");
      setPagesLoaded(false);
      menuPageList();
    } else {
      if (props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        setPageType("JWT_FULL_EMBED");
        setPagesLoaded(false);
        workspacePages();
      } else if (props?.workspaceDetails?.workspace_type === "IFRAME_EMBED") {
        setPageType("IFRAME_EMBED");
        setPagesLoaded(false);
        menuPageList();
      }
    }
  }, [workspacePages, menuPageList, limit, offset, keyword, props?.workspaceDetails, workspaceId]);

  return (
    <PageListGlobalContext.Provider value={{
      keyword,
      setKeyword,
      meta,
      setMeta,
      limit,
      setLimit,
      offset,
      setOffset,
      pagesLoaded,
      setPagesLoaded,
      pageType,
      setPageType,
      pages,
      setPages,
      refreshPages: menuPageList,
      resetPagination
    }}>
      <PageContainer 
        pages={pages}
        isGlobal={true}
        workspaceId={workspaceId}
        workspaceDetails={props.workspaceDetails}
      />
    </PageListGlobalContext.Provider>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  managePageData,
  removePage,
  setPageData,
})(PageListGlobal);