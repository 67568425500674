export const groupBy = (xs, key) => xs.reduce(function(rv, x) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

export const onlyUnique = (value, index, array) => (
  array.indexOf(value) === index
)

export const objectToFormData = (obj, formData = new FormData(), namespace = '') => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let propName = namespace ? `${namespace}[${key}]` : key;
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        if (obj[key] instanceof File) {
          formData.append(propName, obj[key]);
        } else {
          objectToFormData(obj[key], formData, propName);
        }
      } else {
        formData.append(propName, obj[key]);
      }
    }
  }
  return formData;
};

export const isSVGString = (str) => {
  const svgRegex = /<svg(.*?)<\/svg>/i;
  return svgRegex.test(str);
}
