import { FunnelIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoneFound from "src/components/Shared/NoData/NoneFound";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import { operatorNameConverter } from "src/helpers/operators/operatorNameConverter";
import { urlBuilder } from "src/helpers/urlBuilder";
import useFetch from "src/hooks/useFetch";
import { v4 } from "uuid";
import { apiUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";

const GlobalFiltersList = () => {
  const user = useSelector((state) => state.auth.user);
  const { id: userId } = useParams();

  const {
    response: { data: operators = [] },
  } = useFetch("/operators/list");

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filters, setFilters] = useState([]);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true)
  const [customFields, setCustomFields] = useState([])

  useEffect(() => {
    if (userId) {
      const loadFilters = async () => {
        try {
          setFiltersLoaded(false);

          const res = await apiRequest("get", urlBuilder(`users/:user_id/global-filters`, { user_id: userId }), {});

          if (res.data && res.data.status === 200) {
            setFiltersLoaded(true);
            setFilters(res.data.data);
          } else {
            setFiltersLoaded(true);
          }
        } catch (error) {
          toast.error(error.message);
          setFiltersLoaded(true);
        }
      };

      loadFilters();
    }
  }, [userId]);

  const manageFilters = async (filter = {}, showMessage = false, id = null, type = null) => {
    try {
      const res = await apiRequest("post", urlBuilder(`users/:user_id/global-filters/manage`, { user_id: userId }), {
        body: { ...filter, type },
      });

      if (res.data && res.data.status === 200) {
        if (showMessage) {
          toast.success(res.data.message);
        }

        if (res.data.data) {
          setFilters((filters) =>
            filters.map((filter) =>
              filter._id !== id
                ? filter
                : {
                    ...filter,
                    _id: res.data.data,
                  }
            )
          );
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      setCustomFieldsLoading(true)

      try {
        const res = await fetchData(
          "GET",
          `${apiUrl}/users/custom-fields`
        )
        const resData = await res.json()

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data)
        }
      } catch (err) {
        console.dir('err', err)
      } finally {
        setCustomFieldsLoading(false)
      }
    })()
  }, [])

  useEffect(() => { loadCustomFields() }, [ loadCustomFields ])

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map(field => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label
    }))
  ]

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const addFilter = async () => {
    const uniqueId = v4();

    setFilters((filters) => [
      ...filters,
      {
        _id: uniqueId,
        operator_id: null,
        column_name: null,
        value_type: null,
        column_value: null,
        trusted_attribute: null,
      },
    ]);

    await manageFilters({}, false, uniqueId);
  };

  const removeFilter = (id) => {
    const updatedFilters = filters.filter((item) => item._id !== id);
    setFilters(updatedFilters);
    manageFilters({ _id: id }, false, null, "remove");
  };

  return (
    <>
      <Section>
        <div className="grid gap-y-10">
          <div className="w-full flex items-center">
            <H3 caption="Conditions created here will be applied to all dashboards that the user has been given access to see in all workspaces.">Data Access</H3>

            <Button
              version="secondary"
              onClick={addFilter}>
              <IconsWithPlus
                strokeColor={"stroke-highlightColor"}
                item={{ icon: FunnelIcon }}
              />
            </Button>
          </div>
          <hr className="w-full" />
          <div className="flex flex-col gap-y-4">
            {!filtersLoaded ? (
              <Preloader />
            ) : !filters.length ? (
              <NoneFound />
            ) : (
              !customFieldsLoading && filters.map((filter) => (
                <WorkspaceIntegrationFiltersItem
                  key={filter._id}
                  user={user}
                  workspaceId={null}
                  state={filter}
                  operators={operators?.map((operator) => {
                    return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                  })}
                  trustedAttributeArr={allTrustedAttributes}
                  valueType={valueType}
                  setState={(state) => {
                    setFilters(filters.map((filter) => (filter._id !== state._id ? filter : state)));

                    manageFilters(state, false);
                  }}
                  removeFilter={() => removeFilter(filter._id)}
                />
              ))
            )}
          </div>
        </div>
      </Section>
    </>
  );
};

export default GlobalFiltersList;
